import { Button, Divider, Input, Modal, Select } from "antd";
import { useState } from "react";
import { toastError, toastSuccess } from "../../utils/toast";
import { apiUpdateAccountDataSponsorCurrent } from "../../api";
import { useLoading } from "../../context/LoadingContext";
import { systemAccountDataSponsorOptions } from "../../assets/constants/default_option";

export default function ModalAccountDataSponsor({ isOpen, setIsOpen, data, fetchData }: any) {
    const [accountValue, setAccountValue] = useState('')
    const [otpConfirm, setOtpConfirm] = useState('')

    const { setIsLoading } = useLoading()

    const handleUpdate = async () => {
        if (!accountValue) {
            toastError('Vui lòng chọn tài khoản!')
            return
        }
        if (data === accountValue) {
            toastError('Vui lòng chọn tài khoản khác!')
            return
        }
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiUpdateAccountDataSponsorCurrent({
                value: accountValue,
                otpConfirm: otpConfirm,
            })
            if (resp.status === 200) {
                fetchData()
                setIsOpen(false)
                toastSuccess('Cập nhật trạng thái thành công')
                setOtpConfirm('')
                setAccountValue('')
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal
            title="Cập nhật tài khoản Viettel"
            open={isOpen}
            onOk={() => alert()}
            onCancel={() => setIsOpen(false)}
            footer={[
                <Button onClick={handleUpdate} type="primary">
                    Cập nhật
                </Button>
            ]}>
            <Divider />
            <p><span style={{ color: 'red' }}>*</span> Chọn tài khoản Viettel</p>
            <Select
                style={{ width: '100%' }}
                value={accountValue}
                onChange={value => setAccountValue(value)}
                options={systemAccountDataSponsorOptions}
            />
            <p><span style={{ color: 'red' }}>*</span> Nhập mã OTP xác nhận</p>
            <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            <Divider />
        </Modal>
    )
}
