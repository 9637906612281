export const systemAccountDataSponsorOptions = [
    { label: 'Chọn tài khoản', value: '' },
    { label: 'SANN', value: 'data_sponsor' },
    { label: 'Zynet', value: 'datasponsor_zynet' },
]

export const systemAccountAffapiOptions = [
    { label: 'Chọn tài khoản', value: '' },
    { label: 'SANN', value: 'aff_sann' },
    { label: 'Zynet', value: 'aff_zynet' },
]

export const systemProvidorOptions = [
    { label: 'Chọn nhà cung cấp', value: '' },
    { label: 'Affapi', value: 'ds_postpaid' },
    { label: 'DataSponsor', value: 'data_sponsor' },
]

export const TDM_SOURCE = 'TDMSource'

export const partnerPackageDataSponsorSourceOptions = [
    { label: 'DataSponsor', value: 'DataSponsor' },
    { label: 'Affapi', value: 'Affapi' },
    { label: 'TDMSource', value: 'TDMSource' },
]

export const partnerPackageDataCodeSourceOptions = [
    { label: 'DataSponsor', value: 'DataSponsor' },
    { label: 'Affapi', value: 'Affapi' },
]

export const reportType = [
    { label: 'Doanh thu', value: 'revenue' },
    { label: 'DataSponsor', value: 'data_sponsor' },
    { label: 'Datacode', value: 'data_code' },
]
