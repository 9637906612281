import { useCallback, useEffect, useState } from "react"
import { useLoading } from "../../context/LoadingContext"
import { toastError, toastSuccess } from "../../utils/toast"
import { Button, Form, Input, InputNumber, List, Modal, Popconfirm, Select, Space, Table, TableProps } from "antd"
import { LeftCircleOutlined } from "@ant-design/icons"
import { formatCurrency } from "../../utils/string-utils"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Partner } from "../../interfaces/list-partner.interface"
import { apiAddPackageDataCodesPartner, apiGetListPackageDataCodes, apiGetListPartner } from "../../api"
import { DETAIL, PACKAGES } from "../../assets/constants/route_path"
import { partnerPackageDataCodeSourceOptions } from "../../assets/constants/default_option"

const initDataPack = {
    packId: 0,
    packDataCode: '',
    packName: '',
    packPrice: 0,
    packDesc: '',
}

const initCalcPrice = {
    priceUnit: 0,
    price: 0,
    discount: 0,
}

export default function PackageCreatePartnerCode() {
    const { setIsLoading } = useLoading()
    const [partner, setPartner] = useState<Partner>({})
    const [codes, setCodes] = useState<any>([])
    const [listRemoveCodes, setListRemoveCodes] = useState<any>([])
    const [listAddCodes, setListAddCodes] = useState<any>([])
    const [dataPack, setDataPack] = useState<any>(initDataPack)
    const [searchPackKey, setSearchPackKey] = useState<string>('')
    const [searchPackSource, setSearchPackSource] = useState<string>('')
    const [calcPrice, setCalcPrice] = useState(initCalcPrice)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const cpCode = searchParams.get('cpCode')
    const [formCreate] = Form.useForm()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [otpConfirm, setOtpConfirm] = useState<string>('')

    useEffect(() => {
        if (cpCode) {
            fetchPartner(cpCode)
            fetchDataCodes()
        }
    }, [cpCode])

    const fetchPartner = useCallback(async (cpCode: string) => {
        try {
            setIsLoading(true)
            const resCustomers = await apiGetListPartner()
            if (resCustomers.data.partners != null) {
                const partnerInfo = resCustomers.data.partners.find((item: Partner) => item.cpCode === cpCode)
                setPartner(partnerInfo)
                formCreate.setFieldValue('discount', partnerInfo.discount)
            } else {
                setPartner({})
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [])

    const fetchDataCodes = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCode = await apiGetListPackageDataCodes()
            if (resCode.data != null) {
                setCodes(resCode.data.dataCodes)
            } else {
                setCodes({})
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [])

    const handleAddPackage = (values: any) => {
        if (!dataPack.packId) {
            toastError('Vui lòng chọn gói cước!')
            return
        }

        try {
            setIsLoading(true)
            const { packName, packDataCode, packPrice, packDesc } = values
            const { packId } = dataPack
            const packageAddInfo = {
                packageId: packId,
                cpCode: partner.cpCode,
                name: packName,
                dataCode: packDataCode,
                rootPrice: packPrice,
                description: packDesc,
                priceUnit: calcPrice.priceUnit,
                discount: calcPrice.discount,
                price: calcPrice.price,
            }
            setListAddCodes([...listAddCodes, packageAddInfo])
            const newCodes = codes.filter((code: any) => {
                if (code.ID !== packId) {
                    return true
                }
                setListRemoveCodes([...listRemoveCodes, code])
                return false
            })
            setCodes(newCodes)
            toastSuccess('Đã thêm gói cước vào danh sách!')
            setDataPack(initDataPack)
            setCalcPrice(initCalcPrice)
            formCreate.setFieldsValue({ ...initDataPack, discount: partner.discount })
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleRemovePackage = (item: any) => {
        const newListAddCodes = listAddCodes?.filter((pack: any) => {
            if (pack.packageId !== item.packageId) {
                return true
            }
            const newListCodes = listRemoveCodes?.filter((spon: any) => {
                if (spon.ID !== item.packageId) {
                    return true
                }
                setCodes([...codes, spon])
                return false
            })
            setListRemoveCodes(newListCodes)
            return false
        })
        setListAddCodes(newListAddCodes)
    }

    const codeColumns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'STT',
            render: (_text, _record, index) => index + 1,
        },
        {
            key: 'name',
            title: "Tên gói cước",
            dataIndex: 'name',
        },
        {
            key: 'dataCode',
            title: "Mã gói cước",
            dataIndex: 'dataCode',
        },
        {
            key: 'root-price',
            title: "Giá gốc",
            dataIndex: 'rootPrice',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'priceUnit',
            title: "Đơn giá",
            dataIndex: 'priceUnit',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'discount',
            title: "Chiết khấu",
            dataIndex: 'discount',
        },
        {
            key: 'pay-price',
            title: 'Giá bán',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'action',
            render: (data) => (
                <Popconfirm
                    title="Xoá gói cước"
                    description="Xoá gói cước khỏi danh sách?"
                    onConfirm={() => handleRemovePackage(data)}
                    okText="Xác nhận"
                    cancelText="Huỷ bỏ"
                >
                    <Button type="primary" danger>Xoá</Button>
                </Popconfirm>
            )
        }
    ]

    const handleAddPackageDataCodesPartner = async () => {
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiAddPackageDataCodesPartner({
                dataCodes: listAddCodes,
                otpConfirm: otpConfirm
            })
            if (resp.status === 200) {
                toastSuccess('Thêm gói cước thành công!')
                setListAddCodes([])
                setDataPack(initDataPack)
                setCalcPrice(initCalcPrice)
                setIsOpenModal(false)
            } else {
                toastError('Có lỗi xảy ra!. Vui lòng kiểm tra lại thông tin!')
            }
        } catch (error) {
            console.log(error);
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 200, marginBottom: 20 }}>
                <Button
                    onClick={() => navigate(PACKAGES + DETAIL + `?cpCode=${partner.cpCode}&previousTab=dataCode`)}
                    icon={<LeftCircleOutlined />}
                    style={{ background: 'gray', color: 'white' }}
                >
                    Trở lại
                </Button>
                <h2>Đối tác: {partner.name}</h2>
            </div>
            <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                <div style={{ width: 240 }}>
                    <div>
                        <label>Chọn nguồn</label>
                        <Select
                            style={{ width: '100%', marginTop: 10, marginBottom: 20 }}
                            defaultValue={''}
                            value={searchPackSource}
                            onChange={value => setSearchPackSource(value)}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...partnerPackageDataCodeSourceOptions,
                            ]}
                        />
                        <div style={{ marginBottom: 10 }}>Nhập tên gói</div>
                        <Input value={searchPackKey} onChange={e => setSearchPackKey(e.target.value)} placeholder="Nhập tên gói cước" />
                    </div>
                    <div style={{ height: 560, overflow: 'auto', marginTop: 10, paddingLeft: 10 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={codes?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                .filter((item: any) => item.source?.includes(searchPackSource))
                                .filter((item: any) => item.name?.toLowerCase().includes(searchPackKey.toLowerCase()))}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <Button onClick={() => {
                                        const dataPackValue = {
                                            packId: item.ID,
                                            packName: item.name,
                                            packDataCode: item.dataCode,
                                            packSource: item.source,
                                            packPrice: item.priceUnit,
                                            packDesc: item.description,
                                            discount: partner.discount,
                                            priceUnit: item.priceUnit,
                                        }
                                        setDataPack(dataPackValue)
                                        setCalcPrice({
                                            priceUnit: Number(item.priceUnit),
                                            price: item.priceUnit * (1 - Number(partner.discount)),
                                            discount: Number(partner.discount),
                                        })
                                        formCreate.setFieldsValue(dataPackValue)
                                    }} type="text">
                                        {item.name} [{item.source}]
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <Form
                        style={{ maxWidth: 900, width: '90%' }}
                        form={formCreate}
                        onFinish={handleAddPackage}
                        autoComplete='off'
                        labelCol={{ span: 4 }}
                        labelAlign="left"
                        initialValues={dataPack}
                    >
                        <Form.Item
                            label='Tên gói cước'
                            name='packName'
                            rules={[{ required: true, message: 'Vui lòng nhập tên gói cước' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='Tên mã gói'
                            name='packDataCode'
                            rules={[{ required: true, message: 'Vui lòng nhập mã gói cước' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label='Giá gốc' name='packPrice'>
                            <InputNumber disabled formatter={value => formatCurrency(Number(value))} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label='Đơn giá'>
                            <InputNumber
                                value={calcPrice.priceUnit}
                                onChange={value => setCalcPrice({
                                    priceUnit: Number(value),
                                    price: Number(value) * (1 - Number(calcPrice.discount)),
                                    discount: Number(calcPrice.discount),
                                })}
                                formatter={value => formatCurrency(Number(value))}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <div style={{ display: 'flex', gap: 40, flexWrap: 'wrap' }}>
                            <Form.Item label='Giá bán' labelCol={{ span: 8 }} style={{ flex: 1 }}>
                                <InputNumber
                                    formatter={value => formatCurrency(Number(value))}
                                    value={calcPrice.price}
                                    onChange={value => setCalcPrice({
                                        ...calcPrice,
                                        price: Number(value),
                                        discount: Number((1 - Number(value) / calcPrice.priceUnit).toFixed(2))
                                    })}
                                    style={{ width: '100%', marginLeft: 8 }} />
                            </Form.Item>
                            <Form.Item label='Chiết khấu' labelCol={{ span: 6 }} style={{ flex: 1 }}>
                                <InputNumber
                                    value={calcPrice.discount}
                                    onChange={value => setCalcPrice({
                                        ...calcPrice,
                                        discount: Number(value),
                                        price: calcPrice.priceUnit * (1 - Number(value))
                                    })}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                        <Form.Item
                            label='Mô tả'
                            name='packDesc'
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>Gói cước: {dataPack.packDataCode} [{dataPack.packSource}]</h3>
                            <Space>
                                <Button htmlType="submit" type="primary">Thêm</Button>
                                <Button onClick={() => {
                                    setDataPack(initDataPack)
                                    setCalcPrice(initCalcPrice)
                                    formCreate.setFieldsValue({ ...initDataPack, discount: partner.discount })
                                }} type="dashed" danger>Clear</Button>
                            </Space>
                        </div>
                    </Form>
                    <div style={{ marginTop: 20, overflow: 'auto' }}>
                        <div style={{ maxHeight: 360, overflow: 'auto' }}>
                            <Table
                                rowKey={(_, index: any) => index}
                                style={{ overflow: 'auto' }}
                                columns={codeColumns}
                                dataSource={listAddCodes}
                                pagination={false}
                                footer={() => (
                                    <div>Tổng {listAddCodes?.length || '0'} gói cước</div>
                                )}
                            />
                        </div>
                        <div style={{ textAlign: 'end', marginTop: 20 }}>
                            <Button
                                disabled={listAddCodes.length === 0}
                                onClick={() => setIsOpenModal(true)}
                                type="primary">Xác nhận</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title='Thêm gói cước' open={isOpenModal}
                onCancel={() => setIsOpenModal(false)}
                footer={[
                    <Button onClick={handleAddPackageDataCodesPartner} type='primary'>
                        Thêm
                    </Button>,
                ]}>
                <p><span style={{ color: 'red' }}>*</span> Mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            </Modal>
        </div>
    )
}
