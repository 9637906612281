export enum LogoutType {
  UNKNOWN,
  USER_LOGOUT,
  IDLE_SESSION_TIMEOUT,
  PERMISSION_DENIED,
}

export interface ILogoutMetadata {
  logoutType?: LogoutType;
  recentURL?: string;
  recentSearch?: string;
  recentUsername?: string;
}

export interface ILogoutParams {
  logoutType?: LogoutType;
}
