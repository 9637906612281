import {AUTH_LOGIN, PROFILE} from "../../../assets/constants/route_path";
import {Button, Form, type FormProps, Input} from 'antd';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getSession, login} from "../../../redux/slices/authSlice";
import {toastError} from "../../../utils/toast";
import translate from "../../../constants/translate"
import { Image } from 'antd';
import { useLoading } from "../../../context/LoadingContext";
type FieldType = {
    otpCode?: string;
};



const LoginComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Loading context 
    const { setIsLoading } = useLoading();
    // const {translate} = useAppTranslation([NAME_SPACES.AUTH]);
    const onFinish: FormProps<FieldType>["onFinish"] =async (values) => {
        try{
            const {otpCode} =values
            if (otpCode){
                setIsLoading(true)
                await dispatch(login({ otpCode: otpCode})).unwrap()
                // console.log("resp", resp)
                const sessionResp = await dispatch(getSession()).unwrap()
                console.log("sessionResp", sessionResp)
                setIsLoading(false)
                navigate(PROFILE)
            }

         }
         catch (error: any) {
            setIsLoading(false)
            console.log(error);
            const message = error && (error?.data?.message || error?.message || error) 
            toastError(message)
            navigate(AUTH_LOGIN)
        }finally {
            console.log('Login Success:');
        }
    };
    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div
            style={{
                padding: 20,
                backgroundColor: "#FFF",
                borderRadius: 10,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
            }}>
            <Form
                name="basic"
                labelCol={{ span: 20 }}
                wrapperCol={{ span:25 }}
                style={{ minWidth: 300 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout={'vertical'}

            >
                <Form.Item wrapperCol={{ offset: 10, span: 2 }}>
                    <Image
                        width={50}
                        src="./images/logo-sann-group.png"
                        preview = {false}
                    />
                </Form.Item>
                <Form.Item<FieldType>
                    label={'Nhập mã code đăng nhập:'}
                    name="otpCode"
                    rules={[{ required: true, message: "Vui lòng nhập mã code đăng nhập"}]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" style={{background:"#fe1433"}}>
                        {translate.login}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginComponent;
