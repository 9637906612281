import * as Sentry from "@sentry/react";
import React from "react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import {CaptureConsole, HttpClient} from '@sentry/integrations';

let tracePropagationTargets = ["localhost"]
const urlApi = process.env.REACT_APP_SERVER_API_URL
if (urlApi && urlApi.length> 0) {
    tracePropagationTargets.push(urlApi)
}
let isDebug = true
if (process.env.NODE_ENV !== 'development') {
    isDebug = false
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    debug: isDebug,
    environment:process.env.NODE_ENV,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new CaptureConsole({levels: ['error']}),
        new Sentry.Replay(),
        new HttpClient({failedRequestStatusCodes:[[500, 599]]})
    ],
// Set tracesSampleRate to 1.0 to capture 100%
// of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    sendDefaultPii: true,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: tracePropagationTargets,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});
