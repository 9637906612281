import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Select, Space, TableProps } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import CustomTable from '../../components/table';
import defaultParams from '../../assets/constants/default_params';
import { apiGetListPackageDataSponsor } from "../../api";
import { useLoading } from '../../context/LoadingContext';
import { formatCurrency } from '../../utils/string-utils';
import { Link } from 'react-router-dom';
import { CREATE_PACKAGES_SPONSOR, EDIT_PACKAGES_SPONSOR, OVERVIEW, PACKAGES } from '../../assets/constants/route_path';
import ViewPackagesSponsor from './ViewPackagesSponsor';
import { formatDate } from '../../utils/entity-utils';
import { partnerPackageDataSponsorSourceOptions } from '../../assets/constants/default_option';

export default function TabPackagesSponsor() {
    const [sponsors, setSponsors] = useState<any>({})
    const [source, setSource] = useState('')
    const [currentPageSponsor, setCurrentPageSponsor] = useState<number>(defaultParams.PAGE)
    const [pageSizeSponsor, setPageSizeSponsor] = useState<number>(defaultParams.LIMIT)
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()

    const sponsorColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: "STT",
            render: (value, recode, index) => ++index,
        },
        {
            key: 'name',
            title: "Tên gói cước",
            dataIndex: 'name',
        },
        {
            key: 'dataPack',
            title: "Mã gói cước",
            dataIndex: 'dataPack',
        },
        {
            key: 'priceUnit',
            title: "Giá",
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit)}
                </span>
            ),
        },
        {
            key: 'scope',
            title: "Phạm vi",
            dataIndex: 'scope',
        },
        {
            key: 'type',
            title: "Loại",
            dataIndex: 'type',
        },
        {
            key: 'source',
            title: "Nguồn",
            dataIndex: 'source',
        },
        {
            key: 'status',
            title: "Trạng thái",
            dataIndex: 'status',
        },
        {
            key: 'CreatedAt',
            title: "Thời gian tạo",
            dataIndex: 'CreatedAt',
            render: CreatedAt => formatDate(CreatedAt)
        },
        {
            key: 'action',
            render: data => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    <Link to={PACKAGES + OVERVIEW + EDIT_PACKAGES_SPONSOR + `?id=${data.ID}`}>
                        <Button
                            onClick={() => setModalView({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            style={{ background: '#ae7700', color: 'white' }}
                        >
                            Cập nhật
                        </Button>
                    </Link>
                </Space>
            )
        }
    ]

    const fetchDataSponsor = useCallback(async () => {
        try {
            setIsLoading(true)
            const resSponsors = await apiGetListPackageDataSponsor({ source })
            if (resSponsors.data != null) {
                if (source) {
                    const listSponsor = {} as any
                    const listSponsorBySource = resSponsors.data?.packages?.filter((item: any) => item.source === source)
                    listSponsor.packages = listSponsorBySource
                    listSponsor.total = listSponsorBySource?.length
                    setSponsors(listSponsor)
                } else {
                    setSponsors(resSponsors.data)
                }
            } else {
                setSponsors({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [currentPageSponsor, pageSizeSponsor, source])

    useEffect(() => {
        fetchDataSponsor()
    }, [fetchDataSponsor])

    return (
        <>
            <Form
                style={{ display: 'flex', gap: 10 }}
                onFinish={values => setSource(values.source || '')}
                autoComplete='off'
            >
                <Form.Item
                    label='Chọn nguồn'
                    name='source'
                >
                    <Select
                        style={{ width: 200 }}
                        placeholder="Chọn nguồn"
                        defaultValue={''}
                        options={[
                            { label: 'Tất cả', value: '' },
                            ...partnerPackageDataSponsorSourceOptions,
                        ]}
                    />
                </Form.Item>
                <Button htmlType='submit' type="primary" style={{ width: 100 }}>Tìm kiếm</Button>
                <Button htmlType='reset' onClick={() => setSource('')} danger style={{ width: 100 }}>Clear</Button>
            </Form>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Link to={PACKAGES + OVERVIEW + CREATE_PACKAGES_SPONSOR}>
                    <Button type='primary'>Thêm gói cước</Button>
                </Link>
            </div>
            <CustomTable
                namePage='dataSponsors'
                columns={sponsorColumns}
                dataSource={sponsors?.packages}
                pageSize={pageSizeSponsor}
                setPageSize={setPageSizeSponsor}
                total={sponsors.total}
                currentPage={currentPageSponsor}
                setCurrentPage={setCurrentPageSponsor}
            />
            <ViewPackagesSponsor
                modalView={modalView}
                setModalView={setModalView}
            />
        </>
    )
}
