import { Divider, Modal } from 'antd'
import { formatCurrency } from '../../utils/string-utils'
import { formatDate } from '../../utils/entity-utils'

type FieldModalView = {
    modalView: { data: any | null, isOpen: boolean }
    setModalView: Function
}

export default function ViewPackagesCode({ modalView, setModalView }: FieldModalView) {
    return (
        <Modal
            title='Chi tiết gói cước'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <p><b>Tên gói: </b>{modalView.data?.name}</p>
                    <p><b>Giá: </b>{formatCurrency(modalView.data?.priceUnit)}</p>
                    <p><b>Nguồn: </b>{modalView.data?.source}</p>
                    <p><b>Thời gian tạo: </b>{formatDate(modalView.data?.CreatedAt)}</p>
                    <p><b>DataCodeModelId: </b>{modalView.data?.dataCodeModelId}</p>
                </div>
                <div>
                    <p><b>Mã gói: </b>{modalView.data?.dataCode}</p>
                    <p><b>Trạng thái: </b>{modalView.data?.status}</p>
                    <p><b>Loại: </b>{modalView.data?.type}</p>
                    <p><b>Cập nhật: </b>{formatDate(modalView.data?.UpdatedAt)}</p>
                </div>
            </div>
            <span><b>Mô tả: </b>{modalView.data?.description}</span>
        </Modal>
    )
}
