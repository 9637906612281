import {IUser} from "../models/user.model";
import _ from 'lodash';

//Nhóm quản trị
const canUpdateStatusTransactionAdminResource = "PUT::/v1/admins/tx-state" //Cập nhật trạng thái giao dịch
const canGetLisTransactionAdminResource = "GET::/v1/admins/transactions"// Lấy danh sách giao dịch của khách hàng
const canGetListCustomerAdminResource = "GET::/v1/admins/customers"// Lấy danh sách khách hàng
const canTopUpWithdrawAdminResource = "POST::/v1/admins/change-balance"// Thực hiện Nạp tiền/ Rút tiền cho khách hàng

//Nhóm người dùng
const canUpdateUserAdminResource = "PUT:/api/v1/users/:uid/profile-by-admin"; //Cập nhật thông tin người dùng (Admin)
const canUpdateUserResource = "PATCH::/api/v1/users/:uid"; //Cập nhật thông tin người dùng
const canQueryUserAdvanceResource = "GET::/api/v1/users/:uid"; //Truy vấn thông tin người dùng (mở rộng)
const canDeleteUserResource = "DELETE::/api/v1/users/account/:uid"; //Xoá người dùng
const canCreateUserResource = "/api/v1/users/new-user"; // Tạo người dùng
const canQueryUserBasicResource = "GET::/v1/users/:uid" //Truy vấn thông tin người dùng (cơ bản)
const canQueryListUserResource = "GET::/api/v1/users" //Truy vấn danh sách người dùng


// Nhóm khách hàng
const canWithdrawCustomerResource = "GET::/v1/customers/withdraw";//Rút tiền
const canGetBankCustomerResource = "GET::/v1/customers/banks/:id"; // Lấy thông tin ngân hàng
const canGetListBankCustomerResource = "GET::/v1/customers/banks";//Lấy danh sách ngân hàng
const canCreateBankCustomerResource = "POST::/v1/customers/banks";//Tạo thông tin ngân hàng
const canGetCustomerResource = "GET::/v1/customers/:id"; // Lấy thông tin khách hàng
const canGetListCustomerResource = "GET::/v1/customers"; // Lấy danh sách khách hàng

// Nhóm phân quyền
const canDeletePermissionBasicResource = "DELETE::/api/v1/roles/base-permissions/:id";// Xoá quyền cơ sở
const canUpdatePermissionBasicResource = "PUT::/api/v1/roles/base-permissions/:id";//Update quyền cơ sở
const canGetRoleResource = "GET::/api/v1/roles/:id";//Lấy thông tin role
const canDeleteRoleResource = "DELETE::/api/v1/roles/:id";//Xoá role
const canUpdateRoleResource = "PUT::/api/v1/roles/:id"; // Cập nhật thông tin role
const canListRoleResource = "GET::/api/v1/roles";// Lấy danh sách role
const canCreateRoleResource = "POST::/api/v1/roles"; // Tạo role mới
const canGetRoleForUserResource = "GET::/api/v1/roles/users/:id";//Lấy thông tin role của user
const canCreatePermissionBasicResource = "POST::/api/v1/roles/base-permissions"; //Thêm quyền cơ sở
const canGetListPermissionBasicResource = "GET::/api/v1/roles/base-permissions"; //Lấy danh sách quyền cơ sở
export const setUserPermissions = (user: IUser) => {

}

const hasAnyAuthority = (functions: string[], hasAnyFunctions: string[]) => {
    console.log("l;;l;l;l", functions, hasAnyFunctions)
    if (_.isEmpty(functions)) {
        return false;
    }
    if (hasAnyFunctions.length === 0) {
        return true;
    }
    return hasAnyFunctions.some(auth => functions.includes(auth));
};
const PermUtils = {
    //Nhóm quản trị
    canUpdateStatusTransactionAdminResource,
    canGetLisTransactionAdminResource,
    canGetListCustomerAdminResource,
    canTopUpWithdrawAdminResource,
    //Nhóm người dùng
    canUpdateUserAdminResource,
    canUpdateUserResource,
    canQueryUserAdvanceResource,
    canDeleteUserResource,
    canCreateUserResource,
    canQueryUserBasicResource,
    canQueryListUserResource,
    // Nhóm khách hàng
    canWithdrawCustomerResource,
    canGetBankCustomerResource,
    canGetListBankCustomerResource,
    canCreateBankCustomerResource,
    canGetCustomerResource,
    canGetListCustomerResource,

    // Nhóm phân quyền
    canDeletePermissionBasicResource,
    canUpdatePermissionBasicResource,
    canGetRoleResource,
    canDeleteRoleResource,
    canUpdateRoleResource,
    canListRoleResource,
    canCreateRoleResource,
    canGetRoleForUserResource,
    canCreatePermissionBasicResource,
    canGetListPermissionBasicResource,
    setUserPermissions,
    hasAnyAuthority
}

export default PermUtils