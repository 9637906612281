import { useEffect, useState } from 'react'
import { Button, Dropdown, Menu, Space, TableProps, Tag } from 'antd'
import { EyeOutlined, EditOutlined, DownOutlined, GiftOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import CustomTable from '../../components/table'
import { formatCurrency } from '../../utils/string-utils'
import { Link } from 'react-router-dom'
import { CREATE_PARTNET_SPONSOR, CREATE_PARTNET_SPONSOR_PROMOTION, DETAIL, EDIT_PARTNET_SPONSOR, PACKAGES } from '../../assets/constants/route_path'
import ViewPartnerSponsor from './ViewPartnerSponsor'
import { formatDate } from '../../utils/entity-utils'

export default function TabPartnerSponsor({
    sponsors,
    checkPartnerDataSponsor,
    clearEventSponsor,
    customers,
    selectedCustomerDataSponsor,
    setSelectedCustomerDataSponsor,
    selectSponsorRef
}: any) {
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [listSponsors, setListSponsors] = useState([])
    const [promotionType, setPromotionType] = useState('')
    const [networkType, setNetworkType] = useState('')

    const sponsorColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: "STT",
            render: (value, recode, index) => ++index,
        },
        {
            key: 'cpCode',
            title: 'CpCode',
            dataIndex: 'cpCode',
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'dataPack',
            title: 'Mã gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'discount',
            title: 'Discount',
            dataIndex: 'discount',
            render: discount => Number(discount).toFixed(2),
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'priceUnit',
            title: 'Đơn giá',
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit || 0)}
                </span>
            ),
        },
        {
            key: 'network',
            title: 'Nhà cung cấp',
            dataIndex: 'network',
            render: net => net || 'All'
        },
        {
            key: 'promotion',
            title: 'Khuyến mãi',
            dataIndex: 'promotion',
            render: promotion => (
                <Tag
                    style={{ width: 60, textAlign: 'center' }}
                    color={promotion === 'Yes' ? 'green' : 'red'}
                >
                    {promotion}
                </Tag>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
        },
        {
            key: 'CreatedAt',
            title: "Thời gian tạo",
            dataIndex: 'CreatedAt',
            render: CreatedAt => formatDate(CreatedAt)
        },
        {
            key: 'action',
            render: data => (
                <Dropdown
                    placement='bottomRight'
                    overlay={
                        <Menu>
                            <Menu.Item>
                                <Button
                                    onClick={() => setModalView({ data, isOpen: true })}
                                    icon={<EyeOutlined />}
                                    style={{ background: '#289e45', color: 'white', width: 120 }}
                                >
                                    Chi tiết
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to={PACKAGES + DETAIL + CREATE_PARTNET_SPONSOR_PROMOTION + `?cpCode=${data.cpCode}&dataPack=${data.dataPack}`}>
                                    <Button
                                        icon={<GiftOutlined />}
                                        type='primary'
                                        style={{ width: 120 }}
                                    >
                                        Thêm KM
                                    </Button>
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to={PACKAGES + DETAIL + EDIT_PARTNET_SPONSOR + `?cpCode=${data.cpCode}&dataPack=${data.dataPack}`}>
                                    <Button
                                        onClick={() => setModalView({ data, isOpen: true })}
                                        icon={<EditOutlined />}
                                        style={{ background: '#ae7700', color: 'white', width: 120 }}
                                    >
                                        Cập nhật
                                    </Button>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button style={{ background: '#289e45', color: 'white' }}>
                        Chức năng <DownOutlined />
                    </Button>
                </Dropdown>
            )
        }
    ]

    useEffect(() => {
        const listPackages = sponsors?.packages
        const listSponsors = listPackages?.map((item: any) => {
            item.promotion = 'No'
            if (item.parentId === 0) {
                for (let i = 0; i < listPackages?.length; i++) {
                    if (listPackages[i].parentId === item.ID) {
                        item.promotion = 'Yes'
                        break
                    }
                }
                return item
            }
            for (let i = 0; i < listPackages?.length; i++) {
                if (listPackages[i].ID === item.parentId) {
                    item.parentName = listPackages[i].name
                    break
                }
            }
            return item
        })
        const results = listSponsors?.sort((a: any, b: any) => new Date(b.CreatedAt)?.getTime() - new Date(a.CreatedAt)?.getTime())
            .filter((item: any) => {
                if (promotionType) {
                    return item.promotion === promotionType
                }
                return true
            })
            .filter((item: any) => {
                if (networkType === 'All') {
                    return item.network === '' || item.network === 'All'
                }
                if (networkType) {
                    return item.network === networkType
                }
                return true
            })
        setListSponsors(results)
    }, [sponsors?.packages, promotionType, networkType])

    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline' }}>
                    <span style={{ width: 80 }}>Chọn đối tác</span>
                    <Select
                        ref={selectSponsorRef}
                        showSearch
                        style={{ flex: 1, marginBottom: 16, minWidth: '300px' }}
                        placeholder="Chọn khách hàng"
                        optionFilterProp="name"
                        onChange={(value) => setSelectedCustomerDataSponsor(value)}
                        filterOption={(input: any, option: any) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedCustomerDataSponsor}
                    >
                        {customers?.partners?.map((customer: any, idx: React.Key) => (
                            <Select.Option key={idx} value={idx}>
                                {customer.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <Button type="primary" block style={{ width: 100, marginRight: 10 }} onClick={() => checkPartnerDataSponsor()}>Tìm kiếm</Button>
                <Button danger block style={{ width: 100 }} onClick={() => clearEventSponsor()}>Clear</Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <div style={{ display: 'flex', gap: 20 }}>
                    <Space>
                        <label>Khuyến mãi:</label>
                        <Select
                            style={{ width: 120 }}
                            value={promotionType}
                            onChange={value => setPromotionType(value)}
                            options={[
                                { label: 'Tất cả', value: '' },
                                { label: 'Yes', value: 'Yes' },
                                { label: 'No', value: 'No' },
                            ]} />
                    </Space>
                    <Space>
                        <label>Nhà cung cấp:</label>
                        <Select
                            style={{ width: 150 }}
                            value={networkType}
                            onChange={value => setNetworkType(value)}
                            options={[
                                { label: 'Tất cả', value: '' },
                                { label: 'All', value: 'All' },
                                { label: 'data_sponsor', value: 'data_sponsor' },
                                { label: 'datasponsor_zynet', value: 'datasponsor_zynet' },
                                { label: 'aff_sann', value: 'aff_sann' },
                                { label: 'aff_zynet', value: 'aff_zynet' },
                            ]} />
                    </Space>
                </div>
                <Link to={PACKAGES + DETAIL + CREATE_PARTNET_SPONSOR + `?cpCode=${customers.partners[Number(selectedCustomerDataSponsor)]?.cpCode}`}>
                    <Button type='primary'>Thêm gói cước</Button>
                </Link>
            </div>
            <CustomTable
                namePage='dataSponsors'
                columns={sponsorColumns}
                dataSource={listSponsors}
                total={listSponsors?.length || 0}
            />
            <ViewPartnerSponsor
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}
