import { useCallback, useEffect, useRef, useState } from 'react'
import {
    apiGetListPartner,
    apiGetListPackageDataSponsorPartner,
    apiGetListPackageDataCodesPartner,
} from '../../api'
import { Tabs, TabsProps } from 'antd'
import { PartnerList } from '../../interfaces/list-partner.interface'
import { useLoading } from '../../context/LoadingContext'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import TabPartnerSponsor from './TabPartnerSponsor'
import TabPartnerCode from './TabPartnetCode'

const initCustomer = {
    partners: [],
    total: 0,
}

export default function Partner() {
    const [sponsors, setSponsors] = useState<any>({})
    const [selectedCustomerDataSponsor, setSelectedCustomerDataSponsor] = useState<number>(0)
    const selectSponsorRef = useRef(null)

    const [codes, setCodes] = useState<any>({})
    const [selectedCustomerDataCode, setSelectedCustomerDataCode] = useState<number>(0)
    const selectCodeRef = useRef(null)

    const { setIsLoading } = useLoading()
    const [searchParams] = useSearchParams()
    const previousCpCode = searchParams.get('cpCode')
    const previousTab = searchParams.get('previousTab')
    const [customers, setCustomers] = useState<PartnerList>(initCustomer)
    const [defaultCpCode, setDefaultCpCode] = useState('')

    const fetchApiGetListPartner = useCallback(async () => {
        try {
            const resCustomers = await apiGetListPartner()
            if (resCustomers.data.partners != null) {
                setCustomers(resCustomers.data)
                handleGetListPackagePartnerPrevious(resCustomers.data.partners)
            } else {
                setCustomers(initCustomer)
            }
        } catch (error) {
            console.log(error)
        }
    }, [setIsLoading])

    const handleGetListPackagePartnerPrevious = (partners: any) => {
        if (previousCpCode && previousTab) {
            const previousPartner = partners.findIndex((item: any) => item['cpCode'] === previousCpCode)
            switch (`${previousCpCode}_${previousTab}`) {
                case `${previousCpCode}_dataSponsor`:
                    setSelectedCustomerDataSponsor(previousPartner)
                    checkPartnerDataSponsor()
                    break;
                case `${previousCpCode}_dataCode`:
                    setSelectedCustomerDataCode(previousPartner)
                    checkPartnerDataCode()
                    break;
                default:
                    break;
            }
        } else {
            setDefaultCpCode(partners[0].cpCode)
        }
    }

    useEffect(() => {
        fetchApiGetListPartner()
        if (!previousCpCode && defaultCpCode) {
            checkPartnerDataSponsor()
            checkPartnerDataCode()
        }
    }, [previousCpCode, defaultCpCode])

    const checkPartnerDataSponsor = async () => {
        const cpCode = customers.partners[Number(selectedCustomerDataSponsor)]?.cpCode ?? ''
        if (!cpCode && !previousCpCode && !defaultCpCode) {
            toast.error('Vui lòng chọn đối tác')
        }
        try {
            setIsLoading(true)
            const resSponsors = await apiGetListPackageDataSponsorPartner({ cpCode: cpCode || previousCpCode || defaultCpCode })
            if (resSponsors.data.packages != null) {
                setSponsors(resSponsors.data)
            } else {
                setSponsors({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }
    const clearEventSponsor = () => {
        setSponsors({})
        setSelectedCustomerDataSponsor(0)
    }

    const checkPartnerDataCode = async () => {
        const cpCode = customers.partners[Number(selectedCustomerDataCode)]?.cpCode ?? ''
        if (!cpCode && !previousCpCode && !defaultCpCode) {
            toast.error('Vui lòng chọn đối tác')
        }
        try {
            setIsLoading(true)
            const resCodes = await apiGetListPackageDataCodesPartner({ cpCode: cpCode || previousCpCode || defaultCpCode })
            if (resCodes.data.dataCodes != null) {
                setCodes(resCodes.data)
            } else {
                setCodes({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }
    const clearEventCode = () => {
        setCodes({})
        setSelectedCustomerDataCode(0)
    }

    const itemsTab: TabsProps['items'] = [
        {
            key: 'dataSponsor',
            label: 'Data sponsor',
            children: <TabPartnerSponsor
                sponsors={sponsors}
                checkPartnerDataSponsor={checkPartnerDataSponsor}
                clearEventSponsor={clearEventSponsor}
                customers={customers}
                selectedCustomerDataSponsor={selectedCustomerDataSponsor}
                setSelectedCustomerDataSponsor={setSelectedCustomerDataSponsor}
                selectSponsorRef={selectSponsorRef}
            />,
        },
        {
            key: 'dataCode',
            label: 'Data code',
            children: <TabPartnerCode
                codes={codes}
                checkPartnerDataCode={checkPartnerDataCode}
                clearEventCode={clearEventCode}
                customers={customers}
                selectedCustomerDataCode={selectedCustomerDataCode}
                setSelectedCustomerDataCode={setSelectedCustomerDataCode}
                selectCodeRef={selectCodeRef}
            />,
        },
    ]

    return (
        <div>
            <Tabs defaultActiveKey={previousTab || 'dataSponsor'} items={itemsTab} />
        </div>
    )
}
