import { Button, Input, Space, Form, InputNumber, Select, Modal } from 'antd'
import { LeftCircleOutlined } from "@ant-design/icons"
import { DETAIL, PACKAGES } from '../../assets/constants/route_path'
import { formatCurrency } from '../../utils/string-utils'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { apiGetListNetworkProvider, apiGetListPackageDataSponsor, apiGetPackageDataSponsorPartner, apiUpdatePackageDataSponsorPartner } from '../../api'
import { toastError, toastSuccess } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'

const initCalcPrice = {
    priceUnit: 0,
    price: 0,
    discount: 0,
}

export default function PackageEditPartnerSponsor() {
    const [modalUpdate, setModalUpdate] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [calcPrice, setCalcPrice] = useState(initCalcPrice)
    const [packages, setPackages] = useState<any>([])
    const [otpConfirm, setOtpConfirm] = useState<string>('')
    const [sponsorOptions, setSponsorOptions] = useState<any>([])
    const [networks, setNetworks] = useState<any>({})
    const [packSource, setPackSource] = useState('')
    const { setIsLoading } = useLoading()
    const navigate = useNavigate()
    const [formUpdate] = Form.useForm()
    const [searchParams] = useSearchParams()
    const cpCode = searchParams.get('cpCode')
    const dataPack = decodeURIComponent(searchParams.get('dataPack') + window.location.hash)
    const navigateBack = PACKAGES + DETAIL + `?cpCode=${cpCode}&previousTab=dataSponsor`

    const fetchData = useCallback(async () => {
        if (!cpCode || !dataPack) return
        try {
            setIsLoading(true)
            const [respDetailPack, respListPackages, respListNetworks] = await Promise.all([
                apiGetPackageDataSponsorPartner({ cpCode, dataPack }),
                apiGetListPackageDataSponsor(),
                apiGetListNetworkProvider(),
            ])
            let detailPack = {} as any
            if (respDetailPack.data?.package) {
                detailPack = respDetailPack.data.package
                formUpdate.setFieldsValue(detailPack)
                setCalcPrice({
                    priceUnit: detailPack.priceUnit,
                    price: detailPack.price,
                    discount: detailPack.discount,
                })
            }
            if (respListPackages.data?.packages) {
                const listPackages = respListPackages.data.packages
                setPackages(listPackages)
                const listOptions = listPackages.map((pack: any) => {
                    if (pack.ID === detailPack.packageId) {
                        setPackSource(pack.source)
                    }
                    return { label: `${pack.name} - ${formatCurrency(pack.priceUnit)}`, value: pack.ID }
                })
                setSponsorOptions(listOptions)
            }
            if (respListNetworks.data?.networks) {
                let networkBySource = {} as any
                respListNetworks.data.networks.map((net: any) => {
                    if (!networkBySource[net.source]) {
                        networkBySource[net.source] = [{ label: 'All', value: 'All' }]
                    }
                    networkBySource[net.source].push({ label: net.name, value: net.name })
                })
                setNetworks(networkBySource)
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }, [cpCode, dataPack])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleChangePackage = (packageId: Number) => {
        packages.map((pack: any) => {
            if (pack.ID === packageId) {
                setPackSource(pack.source)
                formUpdate.setFieldValue('network', '')
                setCalcPrice({ priceUnit: pack.priceUnit, discount: calcPrice.discount, price: pack.priceUnit * (1 - calcPrice.discount) })
            }
        })
    }

    const handleOpenModal = (values: any) => {
        values.cpCode = cpCode
        setModalUpdate({ data: values, isOpen: true })
    }

    const handleCloseModal = () => {
        setModalUpdate({ data: null, isOpen: false })
        if (otpConfirm) {
            setOtpConfirm('')
        }
    }

    const handleConfirm = async () => {
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            modalUpdate.data.priceUnit = calcPrice.priceUnit
            modalUpdate.data.price = calcPrice.price
            modalUpdate.data.discount = calcPrice.discount
            const resp = await apiUpdatePackageDataSponsorPartner({ otpConfirm, package: modalUpdate.data })
            if (resp.status === 200) {
                toastSuccess('Cập nhật gói cước thành công!')
                handleCloseModal()
                navigate(navigateBack)
            } else {
                toastError('Có lỗi xảy ra!')
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 200, marginBottom: 20 }}>
                <Button
                    onClick={() => navigate(navigateBack)}
                    icon={<LeftCircleOutlined />}
                    style={{ background: 'gray', color: 'white' }}
                >
                    Trở lại
                </Button>
            </div>
            <h2>Đối tác: {cpCode}</h2>
            <Form
                style={{ maxWidth: 900, width: '90%' }}
                form={formUpdate}
                onFinish={handleOpenModal}
                autoComplete='off'
                labelCol={{ span: 4 }}
                labelAlign="left"
            >
                <Form.Item name='ID' style={{ display: 'none' }} />
                <Form.Item
                    label='Gói cước gốc'
                    name='packageId'
                    rules={[{ required: true, message: 'Vui lòng chọn gói cước' }]}
                >
                    <Select
                        onChange={handleChangePackage}
                        placeholder='Chọn gói cước'
                        options={sponsorOptions}
                    />
                </Form.Item>
                <Form.Item
                    label='Tên gói cước'
                    name='name'
                    rules={[{ required: true, message: 'Vui lòng nhập tên gói cước' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Tên mã gói'
                    name='dataPack'
                    rules={[{ required: true, message: 'Vui lòng nhập mã gói cước' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Đơn giá'
                    rules={[{ required: true, message: 'Vui lòng nhập đơn giá' }]}
                >
                    <InputNumber
                        value={calcPrice.priceUnit}
                        onChange={value => setCalcPrice({
                            priceUnit: Number(value),
                            price: Number(value) * (1 - Number(calcPrice.discount)),
                            discount: Number(calcPrice.discount),
                        })}
                        formatter={value => formatCurrency(Number(value))}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <div style={{ display: 'flex', gap: 40, flexWrap: 'wrap' }}>
                    <Form.Item label='Giá bán' labelCol={{ span: 8 }} style={{ flex: 1 }}>
                        <InputNumber
                            style={{ width: '100%', marginLeft: 8 }}
                            formatter={value => formatCurrency(Number(value))}
                            value={calcPrice.price}
                            onChange={value => setCalcPrice({
                                ...calcPrice,
                                price: Number(value),
                                discount: Number((1 - Number(value) / calcPrice.priceUnit).toFixed(2))
                            })}
                        />
                    </Form.Item>
                    <Form.Item label='Chiết khấu' labelCol={{ span: 8 }} style={{ flex: 1 }}>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={calcPrice.discount}
                            onChange={value => setCalcPrice({
                                ...calcPrice,
                                discount: Number(value),
                                price: calcPrice.priceUnit * (1 - Number(value))
                            })}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label='Nhà cung cấp'
                    name='network'
                >
                    <Select
                        placeholder='Chọn nhà cung cấp'
                        options={networks[packSource]}
                    />
                </Form.Item>
                <Form.Item
                    label='Mô tả'
                    name='description'
                >
                    <Input.TextArea />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Space>
                        <Button htmlType="submit" type="primary">Cập nhật</Button>
                        <Button htmlType="reset" type="dashed" danger>Clear</Button>
                    </Space>
                </div>
            </Form>
            <Modal title='Cập nhật gói cước' open={modalUpdate.isOpen}
                onCancel={handleCloseModal}
                footer={[
                    <Button onClick={handleConfirm} type='primary'>
                        Xác nhận
                    </Button>,
                ]}>
                <p><span style={{ color: 'red' }}>*</span> Mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            </Modal>
        </div>
    )
}
