import { Card, Tag, Button, Select, Row, Col } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { apiGetCheckBalance, apiGetListPartner, apiGetWalletBalanceTopUpCurrent } from '../../api';
import { PartnerList } from '../../interfaces/list-partner.interface';
import { formatCurrency } from '../../utils/string-utils';
import TopUpBalanceDeposit from '../../components/modals/TopUpBalanceDeposit';
import PaymentRevenueForPartner from '../../components/modals/PaymentRevenueForPartner';
import RefundForPartner from '../../components/modals/RefundForPartner';
import { useLoading } from '../../context/LoadingContext';

const initCusgomer = {
    partners: [],
    total: 0
}

export default function PartnerWallet() {
    const [customers, setCustomers] = useState<PartnerList>(initCusgomer)
    const [selectedCustomer, setSelectedCustomer] = useState<Number>(0);
    const [balance, setBalnace] = useState<Number>(0);
    const [balanceRefund, setBalnaceRefund] = useState<Number>(0);
    const [balanceRevenue, setBalnaceRevenue] = useState<Number>(0);
    const [cpCode, setCpCode] = useState<string>('');
    // Modal status
    const [isOpenDeposit, setIsOpenDeposit] = useState(false);
    const [isOpenPaymentRevenue, setIsOpenPaymentRevenue] = useState(false);
    const [isOpenRefundForPartner, setIsOpenRefundForPartner] = useState(false);

    // Loading context 
    const { setIsLoading } = useLoading();

    const status = 'Hoạt động';

    useEffect(() => {
        fetchApiGetListPartner()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (customers?.partners?.length > 0) {
            (async () => {
                setIsLoading(true)
                await fetchBalance()
                await fetchBalanceRefund()
                await fetchBalanceRevenue()
                setIsLoading(false)
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers, selectedCustomer])

    const fetchApiGetListPartner = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCustomers = await apiGetListPartner()
            console.log("resCustomers", resCustomers.data.partners)
            if (resCustomers.data.partners != null) {
                setCustomers(resCustomers.data)
            } else {
                setCustomers(initCusgomer)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchBalance() {
        try {
            const cpCode = customers.partners[Number(selectedCustomer)]?.cpCode ?? '';
            setCpCode(cpCode)
            const BalanceTopUpCurrent = await apiGetWalletBalanceTopUpCurrent({ cpCode: cpCode });
            if (BalanceTopUpCurrent?.data?.balance != null) {
                setBalnace(BalanceTopUpCurrent.data.balance)
            } else {
                setBalnace(0)
            }
        } catch (error) {
            console.log(error);
            setBalnace(0)
        }
    }

    async function fetchBalanceRefund() {
        try {
            const cpCode = customers.partners[Number(selectedCustomer)]?.cpCode ?? '';
            const BalanceRefund = await apiGetCheckBalance({ cpCode: `${cpCode}_refund` });
            if (BalanceRefund?.data?.balance != null) {
                setBalnaceRefund(BalanceRefund?.data?.balance)
            } else {
                setBalnaceRefund(0)
            }
        } catch (error) {
            console.log(error);
            setBalnaceRefund(0)
        }
    }

    async function fetchBalanceRevenue() {
        try {
            const cpCode = customers.partners[Number(selectedCustomer)]?.cpCode ?? '';
            const BalanceRevenue = await apiGetCheckBalance({ cpCode: cpCode });
            if (BalanceRevenue?.data?.balance != null) {
                setBalnaceRevenue(Math.abs(BalanceRevenue.data.balance))
            } else {
                setBalnaceRevenue(0)
            }
        } catch (error) {
            console.log(error);
            setBalnaceRevenue(0)
        }
    }

    const closeDeposit = async (requireSync?: boolean) => {
        setIsOpenDeposit(false)
        if (requireSync) {
            await fetchBalance()
        }
    }
    const closePaymentRevenue = async (requireSync?: boolean) => {
        setIsOpenPaymentRevenue(false)
        if (requireSync) {
            await fetchBalanceRevenue()
        }
    }
    const closeRefundForPartner = async (requireSync?: boolean) => {
        setIsOpenRefundForPartner(false)
        if (requireSync) {
            await fetchBalanceRefund()
        }
    }

    const depositEvent = () => setIsOpenDeposit(true)
    const revenuePaymentEvent = () => setIsOpenPaymentRevenue(true)
    const refundToPartners = () => setIsOpenRefundForPartner(true)

    const formatMoney = (money: Number) => money === 0 ? "N/A" : formatCurrency(Number(money));

    return (<div style={{ display: 'flex' }}>
        <TopUpBalanceDeposit
            isModalVisible={isOpenDeposit}
            onClose={closeDeposit}
            cpCode={cpCode ?? ''}
        />
        <PaymentRevenueForPartner
            isModalVisible={isOpenPaymentRevenue}
            onClose={closePaymentRevenue}
            cpCode={cpCode ?? ''}
        />
        <RefundForPartner
            isModalVisible={isOpenRefundForPartner}
            onClose={closeRefundForPartner}
            cpCode={cpCode ?? ''}
        />
        <div style={{ flex: 1 }}>
            <div >
                Chọn đối tác <Select
                    showSearch
                    style={{ flex: 1, marginBottom: 16, minWidth: '50%' }}
                    placeholder="Chọn khách hàng"
                    optionFilterProp="name"
                    onChange={(value) => setSelectedCustomer(value)}
                    filterOption={(input: any, option: any) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {customers?.partners?.map((customer: any, idx: React.Key) => (
                        <Select.Option key={idx} value={idx}>
                            {customer.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <Card title={`Ví tiền: ${customers?.partners?.[Number(selectedCustomer)]?.displayName ?? ''}`}>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1 }}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Số dư">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontSize: 16, fontWeight: 'bold' }}>{formatMoney(balance)}</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Doanh thu">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontSize: 16, fontWeight: 'bold' }}>{formatMoney(balanceRevenue)}</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Số tiền refund trong tháng">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontSize: 16, fontWeight: 'bold' }}>{formatMoney(balanceRefund)}</p>
                                    </div>
                                </Card>
                            </Col>


                        </Row>
                        <Row gutter={16} style={{ marginTop: 10, height: '100%' }}>

                        </Row>
                    </div>
                    <div style={{ width: 200, margin: '0 30px' }}>
                        <div style={{ marginBottom: 10 }}>
                            Trạng thái: <Tag color={status === 'Hoạt động' ? 'green' : 'red'}>{status}</Tag>
                        </div>
                        <Button type="primary" block style={{ marginBottom: 16 }} onClick={depositEvent}>Nạp tiền</Button>
                        <Button type="dashed" block style={{ marginBottom: 16 }} onClick={revenuePaymentEvent}>Thanh toán doanh thu</Button>
                        <Button type="link" block onClick={refundToPartners}>Hoàn tiền cho đối tác</Button>
                    </div>
                </div>
            </Card>
            <Card title="Lịch sử hoạt động" style={{ marginTop: 10 }}>
                {/*<Table dataSource={refundAccounts} columns={[*/}
                {/*    { title: 'Tên tài khoản', dataIndex: 'name' },*/}
                {/*    { title: 'Số tiền cần refund', dataIndex: 'amount' },*/}
                {/*    { title: 'Trạng thái refund', dataIndex: 'status' },*/}
                {/*]} />*/}
            </Card>
        </div>
    </div>
    );
};
