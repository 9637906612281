import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Button, InputNumber } from 'antd';
import { apiPostTopUpBalanceDeposit } from '../../api';
import { TopUpBalanceDepositRequest } from '../../interfaces/request-params.interface';
import { useLoading } from '../../context/LoadingContext';
import { notification } from 'antd';
import { formatCurrency } from '../../utils/string-utils';

type Props = {
    isModalVisible: boolean
    onClose: (requireSync?: boolean) => void
    cpCode?: string
}

const initial = {
    cpCode: '',
    balanceToUp: 0,
    description: '',
    OTPConfirm: '',
}

const rules = {
    amount: [
        { required: true, message: 'Vui lòng nhập số tiền' },
        {
            pattern: /^\d+$/, // Matches positive integers
            validator: (_: any, value: any) => {
                if (Number(value) <= 0) {
                    return Promise.reject('Số tiền phải lớn hơn 0');
                }
                return Promise.resolve();
            },
        },
    ],
    OTPConfirm: [
        { required: true, message: 'Vui lòng nhập mã code đăng nhập' },
        { min: 6, message: 'Mã code đăng nhập phải có 6 ký tự' },
    ],
};

export default function TopUpBalanceDeposit({ isModalVisible, onClose, cpCode }: Props) {
    // Loading context 
    const { setIsLoading } = useLoading();

    const [formData, setFormData] = useState<TopUpBalanceDepositRequest>(initial);
    const [form] = Form.useForm();

    useEffect(() => {
        setFormData({ ...initial, cpCode: cpCode ?? '' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpCode])

    const openNotification = (type: 'success' | 'info' | 'warning' | 'error', message: string, description?: string) => {
        notification[type]({
            message,
            description,
        });
    };


    const handleChange = (event: any) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async () => {
        Modal.confirm({
            title: 'Xác nhận nạp tiền',
            content: `Bạn có muốn thực hiện nạp ${formatCurrency(formData.balanceToUp)} cho đối tác ${formData.cpCode}`,
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            onOk: async () => {
                try {
                    // Implement logic to submit the form data (e.g., API call)
                    setIsLoading(true)
                    console.log('Submitted data:', formData);
                    await apiPostTopUpBalanceDeposit({ ...formData, balanceToUp: Number(formData.balanceToUp) })
                    onClose(true);
                    setIsLoading(false)
                    openNotification('success', 'Nạp tiền thành công')
                } catch (error) {
                    openNotification('error', 'Vui lòng kiểm tra lại thông tin', 'Hãy thử đăng nhập lại')
                    setIsLoading(false)
                    console.log(error);
                }
            },
        })
    };

    const handleClose = () => {
        setFormData({ ...initial, cpCode: cpCode ?? '' })
        form.resetFields();
        onClose()
    }

    return (
        <div>
            <Modal
                title="Nạp tiền vào ví"
                visible={isModalVisible}
                onCancel={handleClose}
                footer={[]}
            >
                <Form layout="vertical" onFinish={handleSubmit} form={form}>
                    <Form.Item label="Mã đối tác (CP Code)">
                        <Input name="cpCode" value={formData.cpCode} onChange={handleChange} disabled />
                    </Form.Item>
                    <Form.Item label="Số tiền cần nạp" rules={rules.amount} name="balanceToUp">
                        <InputNumber
                            style={{ width: '100%' }}
                            formatter={(value) => {
                                return `${formatCurrency(Number(value))}`
                            }}
                            name="balanceToUp"
                            onChange={(value) => {
                                setFormData({ ...formData, balanceToUp: Number(value) })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Mô tả">
                        <Input.TextArea name="description" value={formData.description} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item label="Mã OTP xác nhận" name="OTPConfirm" rules={rules.OTPConfirm}>
                        <Input.Password name="OTPConfirm" value={formData.OTPConfirm} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Xác nhận
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
