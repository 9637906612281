import { Key, useCallback, useEffect, useState } from "react"
import { useLoading } from "../../context/LoadingContext"
import { toastError, toastSuccess } from "../../utils/toast"
import { Button, Form, Input, InputNumber, List, Modal, Popconfirm, Select, Space, Table, TableProps, Tag } from "antd"
import { LeftCircleOutlined } from "@ant-design/icons"
import { formatCurrency } from "../../utils/string-utils"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Partner } from "../../interfaces/list-partner.interface"
import { apiAddPackageDataSponsorsPartner, apiGetListNetworkProvider, apiGetListPackageDataSponsor, apiGetListPartner, apiGetPackageDataSponsorPartner } from "../../api"
import { DETAIL, PACKAGES } from "../../assets/constants/route_path"
import { partnerPackageDataSponsorSourceOptions, TDM_SOURCE } from "../../assets/constants/default_option"

const initDataPack = {
    packId: 0,
    packDataPack: '',
    packName: '',
    packPrice: 0,
    packDesc: '',
    children: [],
    network: '',
}

const initCalcPrice = {
    priceUnit: 0,
    price: 0,
    discount: 0,
}

export default function PackageCreatePartnerSponsor() {
    const { setIsLoading } = useLoading()
    const [partner, setPartner] = useState<Partner>({})
    const [sponsors, setSponsors] = useState<any>([])
    const [sponsorOptions, setSponsorOptions] = useState<any>([])
    const [networks, setNetworks] = useState<any>({})
    const [packSource, setPackSource] = useState('')
    const [listRemoveSponsors, setListRemoveSponsors] = useState<any>([])
    const [listAddPackages, setListAddPackages] = useState<any>([])
    const [dataPack, setDataPack] = useState<any>(initDataPack)
    const [dataPackPromotion, setDataPackPromotion] = useState<any>(initDataPack)
    const [searchPackKey, setSearchPackKey] = useState<string>('')
    const [searchPackSource, setSearchPackSource] = useState<string>('')
    const [calcPrice, setCalcPrice] = useState(initCalcPrice)
    const [calcPricePromotion, setCalcPricePromotion] = useState(initCalcPrice)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const cpCode = searchParams.get('cpCode')
    const [formCreate] = Form.useForm()
    const [formCreatePromotion] = Form.useForm()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [dataModalConfirm, setDataModalConfirm] = useState<any>(null)
    const [dataModalKM, setDataModalKM] = useState<any>(null)
    const [otpConfirm, setOtpConfirm] = useState<string>('')

    const fetchData = useCallback(async () => {
        if (!cpCode) return
        try {
            setIsLoading(true)
            const [respPartners, respListPackages, respListNetworks] = await Promise.all([
                apiGetListPartner(),
                apiGetListPackageDataSponsor(),
                apiGetListNetworkProvider(),
            ])
            if (respPartners.data?.partners) {
                const partnerInfo = respPartners.data.partners.find((item: Partner) => item.cpCode === cpCode)
                setPartner(partnerInfo)
                formCreate.setFieldValue('discount', partnerInfo.discount)
            }
            if (respListPackages.data?.packages) {
                const listPackages = respListPackages.data.packages
                setSponsors(listPackages)
                const listOptions = listPackages?.filter((item: any) => item.source === TDM_SOURCE)
                    .map((item: any) => ({
                        label: `${item.name} - ${formatCurrency(item.priceUnit)} - ${item.source}`,
                        value: `${item.ID}::${item.name}::${item.dataPack}::${item.source}::${item.priceUnit}::${item.description}`
                    }))
                setSponsorOptions(listOptions)
            }
            if (respListNetworks.data?.networks) {
                let networkBySource = {} as any
                respListNetworks.data.networks.map((net: any) => {
                    if (!networkBySource[net.source]) {
                        networkBySource[net.source] = [{ label: 'All', value: 'All' }]
                    }
                    networkBySource[net.source].push({ label: net.name, value: net.name })
                })
                setNetworks(networkBySource)
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }, [cpCode])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSelectPackage = (item: any) => {
        const dataPackValue = {
            packId: item.ID,
            packName: item.name,
            packDataPack: item.dataPack,
            packSource: item.source,
            packPrice: item.priceUnit,
            packDesc: item.description,
            priceUnit: item.priceUnit,
            network: '',
        }
        setDataPack(dataPackValue)
        setCalcPrice({
            priceUnit: Number(item.priceUnit),
            price: item.priceUnit * (1 - Number(partner.discount)),
            discount: Number(partner.discount),
        })
        setPackSource(dataPackValue.packSource)
        formCreate.setFieldsValue(dataPackValue)
    }

    const handleSelectPackagePromotion = (item: any) => {
        const [ID, name, dataPack, source, priceUnit, description] = item.split('::')
        const dataPackValue = {
            packId: ID,
            packName: name,
            packDataPack: dataPack,
            packSource: source,
            packPrice: Number(priceUnit),
            packDesc: description,
            priceUnit: Number(priceUnit),
            price: Number(priceUnit),
            discount: 0,
        }
        setDataPackPromotion(dataPackValue)
        setCalcPricePromotion({
            priceUnit: dataPackValue.priceUnit,
            price: dataPackValue.price,
            discount: dataPackValue.discount,
        })
        formCreatePromotion.setFieldsValue(dataPackValue)
    }

    const handleAddPackage = (values: any) => {
        if (!dataPack.packId) {
            toastError('Vui lòng chọn gói cước!')
            return
        }

        try {
            setIsLoading(true)
            const { packName, packDataPack, packPrice, packDesc, network } = values
            const { packId } = dataPack
            const packageAddInfo = {
                packageId: packId,
                cpCode: partner.cpCode,
                name: packName,
                dataPack: packDataPack,
                rootPrice: packPrice,
                description: packDesc,
                priceUnit: calcPrice.priceUnit,
                discount: calcPrice.discount,
                price: calcPrice.price,
                network: network,
            }
            setListAddPackages([...listAddPackages, packageAddInfo])
            const newSponsors = sponsors.filter((spon: any) => {
                if (spon.ID !== packId) {
                    return true
                }
                setListRemoveSponsors([...listRemoveSponsors, spon])
                return false
            })
            setSponsors(newSponsors)
            toastSuccess('Đã thêm gói cước vào danh sách!')
            setDataPack(initDataPack)
            setCalcPrice(initCalcPrice)
            formCreate.setFieldsValue({ ...initDataPack, discount: partner.discount })
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleRemovePackage = (item: any) => {
        const newListAddPackages = listAddPackages?.filter((pack: any) => {
            if (pack.packageId !== item.packageId) {
                return true
            }
            const newListSponsors = listRemoveSponsors?.filter((spon: any) => {
                if (spon.ID !== item.packageId) {
                    return true
                }
                setSponsors([...sponsors, spon])
                return false
            })
            setListRemoveSponsors(newListSponsors)
            return false
        })
        setListAddPackages(newListAddPackages)
    }

    const sponsorColumns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'STT',
            render: (_text, _record, index) => index + 1,
        },
        {
            key: 'name',
            title: "Tên gói",
            dataIndex: 'name',
        },
        {
            key: 'dataPack',
            title: "Mã gói",
            dataIndex: 'dataPack',
        },
        {
            key: 'root-price',
            title: "Giá gốc",
            dataIndex: 'rootPrice',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'priceUnit',
            title: "Đơn giá",
            dataIndex: 'priceUnit',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'discount',
            title: "Chiết khấu",
            dataIndex: 'discount',
        },
        {
            key: 'pay-price',
            title: 'Giá bán',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'network',
            title: "Nhà cung cấp",
            dataIndex: 'network',
        },
        {
            key: 'pay-price',
            title: 'Tổng số khuyến mãi',
            render: record => record.children?.length || 0,
        },
        {
            key: 'isAdded',
            title: 'Trạng thái',
            render: record => (
                record.ID
                    ? <Tag color="green">Đã thêm</Tag>
                    : <Tag color="red">Chưa thêm</Tag>
            ),
        },
        {
            key: 'action',
            render: (data) => (
                <Space>
                    {/* <Button
                        disabled={data.ID > 0}
                        onClick={() => setDataModalConfirm(data)}
                        type="primary"
                    >
                        Thêm
                    </Button> */}
                    <Button
                        // disabled={!data.ID || data.ID === 0}
                        onClick={() => setDataModalKM(data)}
                        type="primary"
                    >
                        Thêm
                    </Button>
                    <Popconfirm
                        title="Xoá gói cước"
                        description="Xoá gói cước khỏi danh sách?"
                        onConfirm={() => handleRemovePackage(data)}
                        okText="Xác nhận"
                        cancelText="Huỷ bỏ"
                    >
                        <Button type="primary" danger>Xoá</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    const handleAddPackageDataSponsorsPartner = async () => {
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            const listPackages = listAddPackages?.filter((item: any) => !item.children || item.children?.length === 0)
            const resp = await apiAddPackageDataSponsorsPartner({
                packages: listPackages,
                otpConfirm: otpConfirm
            })
            if (resp.status === 200) {
                toastSuccess('Thêm gói cước thành công!')
                setListAddPackages([])
                setDataPack(initDataPack)
                setCalcPrice(initCalcPrice)
                setIsOpenModal(false)
                setOtpConfirm('')
            } else {
                toastError('Có lỗi xảy ra!. Vui lòng kiểm tra lại thông tin!')
            }
        } catch (error) {
            console.log(error);
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddPackageDataSponsorsPartnerSingle = async () => {
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiAddPackageDataSponsorsPartner({
                packages: [dataModalConfirm],
                otpConfirm: otpConfirm
            })
            if (resp.status === 200) {
                toastSuccess('Thêm gói cước thành công!')
                const newListAddPackages = await Promise.all(listAddPackages?.map(async (pack: any) => {
                    if (pack.packageId === dataModalConfirm.packageId) {
                        const respPackage = await apiGetPackageDataSponsorPartner({ cpCode, dataPack: pack.dataPack })
                        if (respPackage.data !== null) {
                            const detailPack = await respPackage.data?.package
                            pack.ID = detailPack?.ID
                        }
                    }
                    return pack
                }))
                setListAddPackages(newListAddPackages)
                setDataModalConfirm(null)
                setOtpConfirm('')
                handleAddPackagePromotion(dataModalKM.addPackagePromotionValues)
            } else {
                toastError('Có lỗi xảy ra!. Vui lòng kiểm tra lại thông tin!')
            }
        } catch (error) {
            console.log(error);
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddPackagePromotion = (values: any) => {
        if (!dataModalKM.ID) {
            dataModalKM.addPackagePromotionValues = values
            setDataModalConfirm(dataModalKM)
            return
        }

        try {
            setIsLoading(true)
            const { packName, packDataPack, packPrice, packDesc } = values
            const { packId } = dataPackPromotion
            const packageAddInfo = {
                packageId: Number(packId),
                cpCode: partner.cpCode,
                name: packName,
                dataPack: packDataPack,
                rootPrice: Number(packPrice),
                description: packDesc,
                priceUnit: calcPricePromotion.priceUnit,
                discount: calcPricePromotion.discount,
                price: calcPricePromotion.price,
                parentId: Number(dataModalKM.ID),
            }
            const newListAddPackages = listAddPackages?.map((pack: any) => {
                if (pack.ID === packageAddInfo.parentId) {
                    return { ...pack, children: [...(pack.children || []), packageAddInfo] }
                }
                return pack
            })
            setListAddPackages([...newListAddPackages, packageAddInfo])
            toastSuccess('Đã thêm gói cước khuyến mãi vào danh sách!')
            setCalcPricePromotion(initCalcPrice)
            formCreatePromotion.setFieldsValue(initDataPack)
            setDataModalKM(null)
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleRemovePackagePromotion = (item: any) => {
        try {
            setIsLoading(true)
            const newListAddPackages = listAddPackages?.filter((pack: any) => {
                return pack.dataPack !== item.dataPack
            })
            const removeChildren = newListAddPackages?.map((pack: any) => {
                if (pack.children?.length > 0) {
                    const listRemoved = pack.children?.filter((pack: any) => pack.dataPack !== item.dataPack)
                    pack.children = listRemoved
                }
                return pack
            })
            setListAddPackages(removeChildren)
            toastSuccess('Đã xoá gói cước khuyến mãi khỏi danh sách!')
            formCreatePromotion.setFieldsValue(initDataPack)
            setDataModalKM(null)
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 200, marginBottom: 20 }}>
                <Button
                    onClick={() => navigate(PACKAGES + DETAIL + `?cpCode=${partner.cpCode}&previousTab=dataSponsor`)}
                    icon={<LeftCircleOutlined />}
                    style={{ background: 'gray', color: 'white' }}
                >
                    Trở lại
                </Button>
                <h2>Đối tác: {partner.name}</h2>
            </div>
            <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                <div style={{ width: 240 }}>
                    <div>
                        <label>Chọn nguồn</label>
                        <Select
                            style={{ width: '100%', marginTop: 10, marginBottom: 20 }}
                            defaultValue={''}
                            value={searchPackSource}
                            onChange={value => setSearchPackSource(value)}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...partnerPackageDataSponsorSourceOptions,
                            ]}
                        />
                        <div style={{ marginBottom: 10 }}>Nhập tên gói</div>
                        <Input value={searchPackKey} onChange={e => setSearchPackKey(e.target.value)} placeholder="Nhập tên gói cước" />
                    </div>
                    <div style={{ height: 560, overflow: 'auto', marginTop: 10, paddingLeft: 10 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={sponsors?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                .filter((item: any) => item.source?.includes(searchPackSource))
                                .filter((item: any) => item.dataPack?.toLowerCase().includes(searchPackKey.toLowerCase()))}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <Button onClick={() => handleSelectPackage(item)} type="text">
                                        {item.dataPack} [{item.source}]
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
                <div style={{ flex: 1, overflow: 'auto' }}>
                    <Form
                        style={{ maxWidth: 900, width: '90%' }}
                        form={formCreate}
                        onFinish={handleAddPackage}
                        autoComplete='off'
                        labelCol={{ span: 4 }}
                        labelAlign="left"
                        initialValues={dataPackPromotion}
                    >
                        <Form.Item
                            label='Tên gói cước'
                            name='packName'
                            rules={[{ required: true, message: 'Vui lòng nhập tên gói cước' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='Tên mã gói'
                            name='packDataPack'
                            rules={[{ required: true, message: 'Vui lòng nhập mã gói cước' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label='Giá gốc' name='packPrice'>
                            <InputNumber disabled formatter={value => formatCurrency(Number(value))} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label='Đơn giá'>
                            <InputNumber
                                value={calcPrice.priceUnit}
                                onChange={value => setCalcPrice({
                                    priceUnit: Number(value),
                                    price: Number(value) * (1 - Number(calcPrice.discount)),
                                    discount: Number(calcPrice.discount),
                                })}
                                formatter={value => formatCurrency(Number(value))}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <div style={{ display: 'flex', gap: 40, flexWrap: 'wrap' }}>
                            <Form.Item label='Giá bán' labelCol={{ span: 8 }} style={{ flex: 1 }}>
                                <InputNumber
                                    formatter={value => formatCurrency(Number(value))}
                                    value={calcPrice.price}
                                    onChange={value => setCalcPrice({
                                        ...calcPrice,
                                        price: Number(value),
                                        discount: Number((1 - Number(value) / calcPrice.priceUnit).toFixed(2))
                                    })}
                                    style={{ width: '100%', marginLeft: 8 }} />
                            </Form.Item>
                            <Form.Item label='Chiết khấu' labelCol={{ span: 6 }} style={{ flex: 1 }}>
                                <InputNumber
                                    value={calcPrice.discount}
                                    onChange={value => setCalcPrice({
                                        ...calcPrice,
                                        discount: Number(value),
                                        price: calcPrice.priceUnit * (1 - Number(value))
                                    })}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                        <Form.Item
                            label='Nhà cung cấp'
                            name='network'
                        >
                            <Select
                                placeholder='Chọn nhà cung cấp'
                                options={networks[packSource]}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Mô tả'
                            name='packDesc'
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>Gói cước: {dataPack.packDataPack} [{dataPack.packSource}]</h3>
                            <Space>
                                <Button htmlType="submit" type="primary">Thêm</Button>
                                <Button onClick={() => {
                                    setDataPack(initDataPack)
                                    setCalcPrice(initCalcPrice)
                                    formCreate.setFieldsValue({ ...initDataPack, discount: partner.discount })
                                }} type="dashed" danger>Clear</Button>
                            </Space>
                        </div>
                    </Form>
                    <div style={{ marginTop: 20, overflow: 'auto' }}>
                        <div style={{ maxHeight: 360, overflow: 'auto' }}>
                            <Table
                                rowKey={(_, index: any) => index}
                                style={{ overflow: 'auto' }}
                                columns={sponsorColumns}
                                dataSource={listAddPackages.filter((item: any) => !item.parentId)}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <div>
                                            {record.children?.map((pack: any, index: Key) => (
                                                <p key={index}>
                                                    <Space>
                                                        <div>
                                                            KM{Number(index) + 1}: {pack.name} - {pack.dataPack} - {formatCurrency(pack.price)}
                                                        </div>
                                                        <Popconfirm
                                                            title="Xoá gói cước"
                                                            description="Xoá gói cước khỏi danh sách?"
                                                            onConfirm={() => handleRemovePackagePromotion(pack)}
                                                            okText="Xác nhận"
                                                            cancelText="Huỷ bỏ"
                                                        >
                                                            <Button danger>Xoá</Button>
                                                        </Popconfirm>
                                                    </Space>
                                                </p>
                                            ))}
                                        </div>
                                    ),
                                    rowExpandable: (record) => record.children?.length > 0,
                                }}
                                pagination={false}
                                footer={() => (
                                    <div>Tổng {listAddPackages?.length || '0'} gói cước</div>
                                )}
                            />
                        </div>
                        <div style={{ textAlign: 'end', marginTop: 20 }}>
                            <Button
                                disabled={listAddPackages.length === 0}
                                onClick={() => setIsOpenModal(true)}
                                type="primary">Xác nhận</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className='custom-modal-width'
                title='Thêm gói khuyến mãi'
                open={dataModalKM !== null}
                onCancel={() => setDataModalKM(null)}
                footer={[]}>
                <Form
                    form={formCreatePromotion}
                    onFinish={handleAddPackagePromotion}
                    autoComplete='off'
                    labelCol={{ span: 4 }}
                    labelAlign="left"
                    initialValues={dataPackPromotion}
                >
                    <Form.Item label='Gói cước'>
                        <Input disabled placeholder={dataModalKM?.name} />
                    </Form.Item>
                    <Form.Item
                        label='Gói khuyến mãi'
                        rules={[{ required: true, message: 'Vui lòng chọn gói khuyến mãi' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            onChange={value => handleSelectPackagePromotion(value)}
                            showSearch
                            filterOption={(input: any, option: any) =>
                                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={sponsorOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Tên gói cước'
                        name='packName'
                        rules={[{ required: true, message: 'Vui lòng nhập tên gói cước' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Tên mã gói'
                        name='packDataPack'
                        rules={[{ required: true, message: 'Vui lòng nhập mã gói cước' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label='Giá gốc' name='packPrice'>
                        <InputNumber
                            disabled
                            formatter={value => formatCurrency(Number(value))}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item label='Đơn giá'>
                        <InputNumber
                            value={calcPricePromotion.priceUnit}
                            onChange={value => setCalcPricePromotion({
                                priceUnit: Number(value),
                                price: Number(value) * (1 - Number(calcPricePromotion.discount)),
                                discount: Number(calcPricePromotion.discount),
                            })}
                            formatter={value => formatCurrency(Number(value))}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <div style={{ display: 'flex', gap: 40, flexWrap: 'wrap' }}>
                        <Form.Item label='Giá bán' labelCol={{ span: 8 }} style={{ flex: 1 }}>
                            <InputNumber
                                value={calcPricePromotion.price}
                                onChange={value => setCalcPricePromotion({
                                    ...calcPricePromotion,
                                    price: Number(value),
                                    discount: Number((1 - Number(value) / calcPricePromotion.priceUnit).toFixed(2))
                                })}
                                formatter={value => formatCurrency(Number(value))}
                                style={{ width: '100%', marginLeft: 8 }}
                            />
                        </Form.Item>
                        <Form.Item label='Chiết khấu' labelCol={{ span: 6 }} style={{ flex: 1 }}>
                            <InputNumber
                                value={calcPricePromotion.discount}
                                onChange={value => setCalcPricePromotion({
                                    ...calcPricePromotion,
                                    discount: Number(value),
                                    price: calcPricePromotion.priceUnit * (1 - Number(value))
                                })}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item
                        label='Mô tả'
                        name='packDesc'
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button htmlType="submit" type="primary">Thêm</Button>
                    </div>
                </Form>
            </Modal>
            <Modal title='Thêm gói cước' open={isOpenModal}
                onCancel={() => setIsOpenModal(false)}
                footer={[
                    <Button onClick={handleAddPackageDataSponsorsPartner} type='primary'>
                        Thêm
                    </Button>,
                ]}>
                <p><span style={{ color: 'red' }}>*</span> Mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            </Modal>
            <Modal title='Thêm gói cước' open={dataModalConfirm}
                onCancel={() => setDataModalConfirm(null)}
                footer={[
                    <Button onClick={handleAddPackageDataSponsorsPartnerSingle} type='primary'>
                        Thêm
                    </Button>,
                ]}>
                <p><span style={{ color: 'red' }}>*</span> Mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            </Modal>
        </div>
    )
}
