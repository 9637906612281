import { useCallback, useEffect, useRef, useState } from 'react'
import {Button, DatePicker, Select, Space, TableProps} from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { Column } from '@ant-design/plots'
import { apiGetListPartner, apiGetListRevenue } from '../../api'
import { PartnerList } from '../../interfaces/list-partner.interface'
import dayjs, { Dayjs } from 'dayjs'
import type { DatePickerProps } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment, { Moment } from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import {values} from "lodash";
import {formatCurrency} from "../../utils/string-utils";

const initCusgomer = {
    partners: [],
    total: 0
}

const typeReport = [
    {
    name:"Theo năm",
    value:"typeYear",
    },
    {
        name:"Theo tháng",
        value:"typeMonth",
    },
    {
        name:"Theo ngày",
        value:"typeDay",
    },
]

const listMonth = [
    {
        name:"Tháng 1",
        value:"01",
    },
    {
        name:"Tháng 2",
        value:"02",
    },
    {
        name:"Tháng 3",
        value:"03",
    },
    {
        name:"Tháng 4",
        value:"04",
    },
    {
        name:"Tháng 5",
        value:"05",
    },
    {
        name:"Tháng 6",
        value:"06",
    },
    {
        name:"Tháng 7",
        value:"07",
    },
    {
        name:"Tháng 8",
        value:"08",
    },
    {
        name:"Tháng 9",
        value:"09",
    },
    {
        name:"Tháng 10",
        value:"10",
    },
    {
        name:"Tháng 11",
        value:"11",
    },
    {
        name:"Tháng 12",
        value:"12",
    },
    ]

const dateFormat = 'YYYY-MM-DD';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

export default function RevenuePage() {
    const [customers, setCustomers] = useState<PartnerList>(initCusgomer)
    const [revenues, setRevenues] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [dates, setDates] = useState<any>()
    const [selectedCustomerDataCode, setSelectedCustomerCode] = useState<number>(0)
    const [selectedTypeReport, setSelectedTypeReport] = useState(typeReport[0].value)
    const selectCodeRef = useRef(null)
    const [months, setMonths] = useState<[Dayjs, Dayjs] | null>([dayjs().startOf("year"), dayjs()]);
    const [selectedYear, setSelectedYear] = useState(dayjs());
    const [monthCurrent, setMonthCurrent] = useState("01");


    const {setIsLoading} = useLoading()


    const onChangeMonth: RangePickerProps['onChange'] = (dates, dateStrings) => {
        setMonths(dates as [Dayjs, Dayjs]);
        console.log('Selected Time: ', dates);
        console.log('Formatted Selected Time: ', dateStrings);
    };

    const onChangeDate: RangePickerProps['onChange'] = (dates, dateStrings) => {
        setDates(dates as [Dayjs, Dayjs]);
        console.log('Selected Time: ', dates);
        console.log('Formatted Selected Time: ', dateStrings);
    };

    const columns: TableProps<any>['columns'] = [
        {
            key: 'date',
            title: "Date",
            dataIndex: 'date',
        },
        {
            key: 'revenue',
            title: "revenue",
            dataIndex: 'revenue',
            render: (revenue) => formatCurrency(revenue)
        },
    ]


    const fetchData = useCallback(async (cpCode: string) => {
        try {
            setIsLoading(true)
            const resSponsors = await apiGetListRevenue({
                page: currentPage,
                size: pageSize,
                cpCode: customers.partners[selectedCustomerDataCode]?.cpCode || cpCode,
                fromDate: months ? months[0].format(defaultParams.FORMAT_DATE) : defaultParams.FROM_DATE,
                toDate: months ? months[1].format(defaultParams.FORMAT_DATE) : defaultParams.TO_DATE,
                typeReport: selectedTypeReport,
            })
            if (resSponsors.data.revenues != null) {
                setRevenues(resSponsors.data)
            } else {
                setRevenues({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [currentPage, pageSize])

    const fetchApiGetListPartner = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCustomers = await apiGetListPartner()
            if (resCustomers.data.partners != null) {
                if (customers.partners.length === 0) {
                    fetchData(resCustomers.data.partners[0]?.cpCode)
                }
                setCustomers(resCustomers.data)
            } else {
                setCustomers(initCusgomer)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [])


    useEffect(() => {
        if (customers.partners[0]?.cpCode) {
            fetchData(customers.partners[0]?.cpCode)
        } else {
            fetchApiGetListPartner()
        }
    }, [fetchData])

    const config = {
        data: revenues.revenues?.map((item: any) => ({datetime: item.date, value: item.revenue})),
        xField: 'datetime',
        yField: 'value',
        label: {
            position: 'top',
            style: {
                fill: '#FFFFFF',
                fontWeight: 700,
            },
        },
    }

    const clearEventCode = async () => {
        setSelectedCustomerCode(0)
        setSelectedTypeReport("typeYear")
        setMonths([dayjs().startOf("year"), dayjs()])
        try {
            setIsLoading(true)
            const resSponsors = await apiGetListRevenue({
                page: defaultParams.PAGE,
                size: defaultParams.LIMIT,
                cpCode: customers.partners[0]?.cpCode,
                fromDate: dayjs().startOf("year").format(dateFormat),
                toDate: dayjs().format(dateFormat),
                typeReport: selectedTypeReport,
            })
            if (resSponsors.data.revenues != null) {
                setRevenues(resSponsors.data)
            } else {
                setRevenues({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const handleSearch = async () => {
        try {
            setIsLoading(true)
            let fromDate = months ? months[0].format(defaultParams.FORMAT_DATE) : defaultParams.FROM_DATE
            let toDate =  months ? months[1].format(defaultParams.FORMAT_DATE) : defaultParams.TO_DATE
            if (selectedTypeReport == "typeDay"){
                fromDate = dates ? dates[0].format(defaultParams.FORMAT_DATE) : defaultParams.FROM_DATE
                toDate =  dates ? dates[1].format(defaultParams.FORMAT_DATE) : defaultParams.TO_DATE
            }
            let body ={
                cpCode: customers.partners[selectedCustomerDataCode]?.cpCode || customers.partners[0]?.cpCode,
                fromDate: fromDate,
                toDate: toDate,
                typeReport: selectedTypeReport,
            }
            const resSponsors = await apiGetListRevenue(body)
            if (resSponsors.data.revenues != null) {
                setRevenues(resSponsors.data)
            } else {
                setRevenues({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }


    const onChangeYear: DatePickerProps['onChange'] = (date, dateString) => {
        setSelectedYear(date as Dayjs);
        console.log(date, dateString);
    };

    const updateMonthByYear =(year:Dayjs)=> {
        const day = dayjs()
        let minDate = dayjs(`${day.year()}-01-01`, 'YYYY-MM-DD'); // Minimum date
        let maxDate = dayjs(`${day.year()}-12-31`, 'YYYY-MM-DD'); // Maximum date
        if (selectedYear && selectedYear.year()) {
            minDate = dayjs(`${year.year()}-01-01`, 'YYYY-MM-DD'); // Minimum date
            maxDate = dayjs(`${year.year()}-12-31`, 'YYYY-MM-DD'); // Maximum date
        }
        setMonths([minDate, maxDate]);
    }

    useEffect(() => {
        updateMonthByYear(selectedYear)
    }, [ selectedYear && selectedYear.year()]);

    const disabledMonth = (current: Dayjs) => {
        // Disable dates before minDate and after maxDate
        const day = dayjs()
        let minDate = dayjs(`${day.year()}-01-01`, 'YYYY-MM-DD'); // Minimum date
        let maxDate = dayjs(`${day.year()}-12-31`, 'YYYY-MM-DD'); // Maximum date
        if (selectedYear && selectedYear.year()) {
            minDate = dayjs(`${selectedYear.year()}-01-01`, 'YYYY-MM-DD'); // Minimum date
            maxDate = dayjs(`${selectedYear.year()}-12-31`, 'YYYY-MM-DD'); // Maximum date
        }
        return current && (current < minDate || current > maxDate);
    };

    const disabledDate = (current: Dayjs) => {
        // Disable dates before minDate and after maxDate
        const day = dayjs()
        let minDate = dayjs(`${day.year()}-${monthCurrent}-01`, 'YYYY-MM-DD'); // Minimum date
        let maxDate = dayjs(`${day.year()}-${monthCurrent}-31`, 'YYYY-MM-DD'); // Maximum date
        if (selectedYear && selectedYear.year()) {
            minDate = dayjs(`${selectedYear.year()}-${monthCurrent}-01`, 'YYYY-MM-DD'); // Minimum date
            maxDate = dayjs(`${selectedYear.year()}-${monthCurrent}-31`, 'YYYY-MM-DD'); // Maximum date
        }
        return current && (current < minDate || current > maxDate);
    };

    const onChangeMonthCurrent = (month: string) => {
        setMonthCurrent(month)
        const day = dayjs()
        let minDate = dayjs(`${day.year()}-${month}-01`, 'YYYY-MM-DD'); // Minimum date
        if (selectedYear && selectedYear.year()) {
            minDate = dayjs(`${selectedYear.year()}-${month}-01`, 'YYYY-MM-DD'); // Minimum date
        }
        setDates([minDate, minDate.endOf("month")]);
    }
    const onChangeTypeReport = (value: string) => {
        const day = dayjs()
        setSelectedTypeReport(value)
        setSelectedYear(day)
        updateMonthByYear(day)
        onChangeMonthCurrent("01")

}


    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
                <div style={{display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline'}}>
                    <span style={{width: 80}}>Chọn đối tác</span>
                    <Select
                        ref={selectCodeRef}
                        showSearch
                        style={{flex: 1, marginBottom: 16, minWidth: '300px'}}
                        placeholder="Chọn khách hàng"
                        optionFilterProp="name"
                        onChange={(value) => setSelectedCustomerCode(value)}
                        filterOption={(input: any, option: any) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedCustomerDataCode}
                    >
                        {customers?.partners?.map((customer: any, idx: React.Key) => (
                            <Select.Option key={idx} value={idx}>
                                {customer.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div style={{display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline'}}>
                    <span style={{width: 80}}>Chọn loại báo cáo</span>
                    <Select
                        ref={selectCodeRef}
                        showSearch
                        style={{flex: 1, marginBottom: 16, minWidth: '300px'}}
                        placeholder="Chọn loại báo cáo"
                        optionFilterProp="name"
                        onChange={onChangeTypeReport}
                        filterOption={(input: any, option: any) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedTypeReport}

                    >
                        {typeReport?.map((data: any, idx: React.Key) => (
                            <Select.Option key={idx} value={data.value}>
                                {data.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div style={{display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline'}}>
                    <span style={{width: 80}}>Chọn thời gian</span>
                    <Space direction="vertical">
                        <DatePicker onChange={onChangeYear} picker="year" value={selectedYear}/>
                        {selectedTypeReport == "typeMonth" && <DatePicker.RangePicker
                            value={months}
                            onChange={onChangeMonth}
                            format="YYYY-MM"
                            picker="month"
                            disabledDate={disabledMonth}
                        />}
                        {selectedTypeReport == "typeDay" && <>
                            <Select
                                ref={selectCodeRef}
                                showSearch
                                style={{flex: 1, marginBottom: 16, minWidth: '300px'}}
                                placeholder="Chọn tháng"
                                optionFilterProp="name"
                                onChange={onChangeMonthCurrent}
                                filterOption={(input: any, option: any) =>
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={monthCurrent}

                            >
                                {listMonth?.map((data: any, idx: React.Key) => (
                                    <Select.Option key={idx} value={data.value}>
                                        {data.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <DatePicker.RangePicker
                                value={dates}
                                onChange={onChangeDate}
                                format={dateFormat}
                                picker="date"
                                disabledDate={disabledDate}
                            /></>}
                    </Space>
                    <Space direction="vertical">
                    </Space>
                </div>
                <Button type="primary" block style={{width: 100, marginRight: 10}} onClick={handleSearch}>Báo cáo</Button>
                <Button danger block style={{width: 100}} onClick={clearEventCode}>Clear</Button>
            </div>
            {revenues.revenues && <Column {...config} />}
            <CustomTable
                namePage='data'
                columns={columns}
                dataSource={revenues.revenues}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={revenues.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}
