import {Outlet, useNavigate} from 'react-router-dom'
import Loading from '../../components/loading'
import {useSelector} from 'react-redux'
import {authSelector} from '../../redux/slices/authSlice'
import React, {useEffect} from "react";
import {HOME} from "../../assets/constants/route_path";
import {IDLE_SESSION_TIMEOUT_MINUTES} from "../../config/constants";
import styled from "styled-components";
import {ILogoutMetadata, LogoutType} from "../../models/logout.model";
import NAME_SPACES from "../../i18n/helpers/nameSpaces";
import {useGetContentTranslation} from "../../hook";

export default function AuthPage() {
    const authState = useSelector(authSelector)
    const navigate = useNavigate()
    const {logoutMetadata} = authState
    useEffect(() => {
        if (authState.isAuthenticated) {
            navigate(HOME)
        }
    }, [authState, navigate])
    return <div>
        {!authState.isLoading ? <>
            {RenderFlashMessage(logoutMetadata)}
            <Outlet/>
        </> :
        <Loading/>}
    </div>
}
function RenderFlashMessage(metadata: ILogoutMetadata) {
    const FlashMessage = styled.div`
      width: 100%;
      height: 40px;
      display: flex;
      -moz-box-pack: justify;
      justify-content: center;
      -moz-box-align: center;
      align-items: center;
      background: #3e515b;
      color: rgb(255, 255, 255);
      border: 0px none;
      padding: 0px;
      margin: 0px;
      transition: background 0.3s ease 0s;
    `;
    const msgIdleSessionTimeout= useGetContentTranslation("idleSessionTimeout",NAME_SPACES.AUTH,{"timeoutMinutes":IDLE_SESSION_TIMEOUT_MINUTES})
    return <>{metadata && metadata.logoutType === LogoutType.IDLE_SESSION_TIMEOUT && (
        <FlashMessage>
            {msgIdleSessionTimeout}
        </FlashMessage>)}</>;
}
