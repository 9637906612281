const defaultParams = {
    PAGE: 1,
    LIMIT: 10,
    START_DATE: '01-01-2024',
    END_DATE: '31-12-2024',
    FORMAT_TIME: 'DD-MM-YYYY',
    FROM_DATE: '2024-03-01',
    TO_DATE: '2024-05-01',
    FORMAT_DATE: 'YYYY-MM-DD',
}

export default defaultParams