import { Divider, Modal, Space } from 'antd'
import { formatCurrency } from '../../utils/string-utils'
import { useEffect, useState } from 'react'
import { useLoading } from '../../context/LoadingContext'
import { apiGetPackageDataCodeV2 } from '../../api'
import { formatDate } from '../../utils/entity-utils'

type FieldModalView = {
    modalView: { data: any | null, isOpen: boolean }
    setModalView: Function
}

export default function ViewPartnerCode({ modalView, setModalView }: FieldModalView) {
    const [pack, setPack] = useState<any>({})
    const { setIsLoading } = useLoading()

    useEffect(() => {
        const fetchPack = async () => {
            try {
                setIsLoading(true)
                const resp = await apiGetPackageDataCodeV2({
                    id: modalView.data?.packageId,
                })
                if (resp.data.dataCode != null) {
                    setPack(resp.data.dataCode)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false)
            }
        }
        if (modalView.data?.packageId) {
            fetchPack()
        } else {
            setPack({})
        }
    }, [modalView])

    return (
        <Modal
            title='Chi tiết gói cước'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <Space>
                <h3 style={{ margin: 0 }}>Gói cước đối tác:</h3>
                <span>{modalView.data?.cpCode}</span>
            </Space>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <p><b>Tên gói: </b>{modalView.data?.name}</p>
                    <p><b>Đơn giá: </b>{formatCurrency(modalView.data?.priceUnit)}</p>
                    <p><b>Giá bán: </b>{formatCurrency(modalView.data?.price)}</p>
                    <p><b>Thời gian tạo: </b>{formatDate(modalView.data?.CreatedAt)}</p>
                </div>
                <div>
                    <p><b>Mã gói: </b>{modalView.data?.dataCode}</p>
                    <p><b>Trạng thái: </b>{modalView.data?.status}</p>
                    <p><b>Discount: </b>{modalView.data?.discount}</p>
                    <p><b>Cập nhật: </b>{formatDate(modalView.data?.UpdatedAt)}</p>
                </div>
            </div>
            <span><b>Mô tả: </b>{modalView.data?.description}</span>
            <Divider />
            <h3 style={{ margin: 0 }}>Gói cước gốc</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <p><b>Tên gói: </b>{pack.name}</p>
                    <p><b>Giá: </b>{formatCurrency(pack.priceUnit)}</p>
                    <p><b>Nguồn: </b>{pack.source}</p>
                    <p><b>Thời gian tạo: </b>{formatDate(pack.CreatedAt)}</p>
                    <p><b>DataCodeModelId: </b>{pack.dataCodeModelId}</p>
                </div>
                <div>
                    <p><b>Mã gói: </b>{pack.dataCode}</p>
                    <p><b>Trạng thái: </b>{pack.status}</p>
                    <p><b>Loại: </b>{pack.type}</p>
                    <p><b>Cập nhật: </b>{formatDate(pack.UpdatedAt)}</p>
                </div>
            </div>
            <span><b>Mô tả: </b>{pack.description}</span>
        </Modal>
    )
}
