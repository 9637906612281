import { Button, Space, TableProps } from 'antd'
import { Select } from 'antd'
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import { formatCurrency } from '../../utils/string-utils'
import { Link } from 'react-router-dom'
import { CREATE_PARTNET_CODE, DETAIL, EDIT_PARTNET_CODE, PACKAGES } from '../../assets/constants/route_path'
import { useState } from 'react'
import ViewPartnerCode from './ViewPartnerCode'
import { formatDate } from '../../utils/entity-utils'

export default function TabPartnerCode({
    codes,
    checkPartnerDataCode,
    clearEventCode,
    customers,
    selectedCustomerDataCode,
    setSelectedCustomerDataCode,
    selectCodeRef
}: any) {
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const codeColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: "STT",
            render: (value, recode, index) => ++index,
        },
        {
            key: 'cpCode',
            title: 'CpCode',
            dataIndex: 'cpCode',
        },
        {
            key: 'dataCode',
            title: 'Mã gói cước',
            dataIndex: 'dataCode',
        },
        {
            key: 'discount',
            title: 'Discount',
            dataIndex: 'discount',
            render: discount => Number(discount).toFixed(2),
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'priceUnit',
            title: 'Đơn giá',
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit || 0)}
                </span>
            ),
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
        },
        {
            key: 'CreatedAt',
            title: "Thời gian tạo",
            dataIndex: 'CreatedAt',
            render: CreatedAt => formatDate(CreatedAt)
        },
        {
            key: 'action',
            render: data => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    <Link to={PACKAGES + DETAIL + EDIT_PARTNET_CODE + `?cpCode=${data.cpCode}&dataCode=${data.dataCode}`}>
                        <Button
                            onClick={() => setModalView({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            style={{ background: '#ae7700', color: 'white' }}
                        >
                            Cập nhật
                        </Button>
                    </Link>
                </Space>
            )
        }
    ]

    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline' }}>
                    <span style={{ width: 80 }}>Chọn đối tác</span>
                    <Select
                        ref={selectCodeRef}
                        showSearch
                        style={{ flex: 1, marginBottom: 16, minWidth: '300px' }}
                        placeholder="Chọn khách hàng"
                        optionFilterProp="name"
                        onChange={(value) => setSelectedCustomerDataCode(value)}
                        filterOption={(input: any, option: any) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedCustomerDataCode}
                    >
                        {customers?.partners?.map((customer: any, idx: React.Key) => (
                            <Select.Option key={idx} value={idx}>
                                {customer.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <Button type="primary" block style={{ width: 100, marginRight: 10 }} onClick={() => checkPartnerDataCode()}>Tìm kiếm</Button>
                <Button danger block style={{ width: 100 }} onClick={() => clearEventCode()}>Clear</Button>
            </div>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Link to={PACKAGES + DETAIL + CREATE_PARTNET_CODE + `?cpCode=${customers.partners[Number(selectedCustomerDataCode)]?.cpCode}`}>
                    <Button type='primary'>Thêm gói cước</Button>
                </Link>
            </div>
            <CustomTable
                namePage='dataCodes'
                columns={codeColumns}
                dataSource={codes?.dataCodes}
                total={codes.total}
            />
            <ViewPartnerCode
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}
