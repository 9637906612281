import { Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";
import translate from "../../constants/translate";

const routes: Record<string, string> = {
  "/user": "User",
  "/customers": translate.customers,
  "/sharks": translate.shark,
  "/votes": translate.votes,
  "/banks": "Ngân hàng",
  "/transactions": "Giao dịch",
  "/admin": translate.admin,
  "/admin/:id": 'Chỉnh sửa',
  "/packages": "Gói cước",
  "/packages/overview": "Tổng quan",
  "/packages/overview/create-packages-sponsor": "Thêm data sponsor",
  "/packages/overview/create-packages-code": "Thêm data code",
  "/packages/overview/edit-packages-sponsor": "Cập nhật data sponsor",
  "/packages/overview/edit-packages-code": "Cập nhật data code",
  "/packages/detail": "Đối tác",
  "/packages/detail/create-partner-sponsor": "Thêm data sponsor",
  "/packages/detail/create-partner-code": "Thêm data code",
  "/packages/detail/edit-partner-sponsor": "Cập nhật data sponsor",
  "/packages/detail/edit-partner-code": "Cập nhật data code",
  "/revenues": "Doanh thu",
  "/revenues/overview": "Tổng quan",
  "/revenues/report": "Báo cáo",
  "/system": "Quản trị hệ thống",
};

const BreadcrumbItem = () => {
  const location = useLocation();

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((str, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    let breadcrumbName = "";
    Object.keys(routes).forEach((pattern) => {
      const regex = new RegExp(pattern);

      if (regex.test(url)) {
        breadcrumbName = routes[pattern];
      }
    });

    return {
      key: url,
      title: breadcrumbName,
    };
  });

  return <Breadcrumb items={extraBreadcrumbItems} />;
};
export default BreadcrumbItem;
