import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Button, notification, InputNumber } from 'antd';
import { PaymentRevenueForPartnerRequest } from '../../interfaces/request-params.interface';
import { apiPostPaymentRevenueForPartner } from '../../api';
import { useLoading } from '../../context/LoadingContext';
import { formatCurrency } from '../../utils/string-utils';

type Props = {
    isModalVisible: boolean
    onClose: (requireSync?: boolean) => void
    cpCode?: string
}


const initial = {
    cpCode: '',
    revenue: 0,
    description: '',
    OTPConfirm: '',
}

const rules = {
    amount: [
        { required: true, message: 'Vui lòng nhập số tiền' },
        {
            pattern: /^\d+$/, // Matches positive integers
            validator: (_: any, value: any) => {
                if (Number(value) <= 0) {
                    return Promise.reject('Số tiền phải lớn hơn 0');
                }
                return Promise.resolve();
            },
        },
    ],
    OTPConfirm: [
        { required: true, message: 'Vui lòng nhập mã code đăng nhập' },
        { min: 6, message: 'Mã code đăng nhập phải có 6 ký tự' },
    ],
};

export default function PaymentRevenueForPartner({ isModalVisible, onClose, cpCode }: Props) {
    // Loading context 
    const { setIsLoading } = useLoading();

    const [formData, setFormData] = useState<PaymentRevenueForPartnerRequest>(initial);
    const [form] = Form.useForm();
    useEffect(() => {
        setFormData({ ...formData, cpCode: cpCode ?? '' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpCode])

    // useEffect(() => {
    //     const money = removeSpecialChars(String(formData.revenue))
    //     setFormData({
    //         ...formData,
    //         revenue: formatCurrency(Number(money)) as any,
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [formData.revenue])

    const handleChange = (event: any) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };


    function removeSpecialChars(str: string): string {
        const regex = /[^0-9]/g;
        return str.replace(regex, '');
    }

    const openNotification = (type: 'success' | 'info' | 'warning' | 'error', message: string, description?: string) => {
        notification[type]({
            message,
            description,
        });
    };


    const handleSubmit = async () => {
        Modal.confirm({
            title: `Xác nhận thanh toán doanh thu`,
            content: `Bạn có muốn thanh toán doanh thu ${formatCurrency(formData.revenue)} cho đối tác ${formData.cpCode}`,
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            onOk: async () => {
                try {
                    // Implement logic to submit the form data (e.g., API call)
                    setIsLoading(true)
                    console.log('Submitted data:', formData);
                    await apiPostPaymentRevenueForPartner({ ...formData, revenue: Number(formData.revenue) })
                    onClose(true);
                    setIsLoading(false)
                    openNotification('success', 'Thanh toán doanh thu thành công')
                } catch (error) {
                    openNotification('error', 'Vui lòng kiểm tra lại thông tin', 'Hãy thử đăng nhập lại')
                    setIsLoading(false)
                }
            },
        })
    };

    const handleClose = () => {
        setFormData({ ...initial, cpCode: cpCode ?? '' })
        form.resetFields();
        onClose()
    }

    return (
        <Modal
            title="Thanh toán doanh thu"
            visible={isModalVisible}
            onCancel={handleClose}
            footer={[]}
        >
            <Form layout="vertical" onFinish={handleSubmit}  form={form}>
                <Form.Item label="Mã đối tác (CP Code)">
                    <Input name="cpCode" value={formData.cpCode} onChange={handleChange} disabled />
                </Form.Item>
                <Form.Item label="Số tiền doanh thu" rules={rules.amount} name="revenue">
                    <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => {
                            return `${formatCurrency(Number(value))}`
                        }}
                        name="revenue"
                        onChange={(value) => {
                            setFormData({ ...formData, revenue: Number(value) })
                        }}
                    />
                </Form.Item>
                <Form.Item label="Mô tả">
                    <Input.TextArea name="description" value={formData.description} onChange={handleChange} />
                </Form.Item>
                <Form.Item label="Mã OTP xác nhận" name="OTPConfirm" rules={rules.OTPConfirm}>
                    <Input.Password name="OTPConfirm" value={formData.OTPConfirm} onChange={handleChange} />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        Xác nhận
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
