/* eslint-disable no-empty-pattern */
import React from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { IDLE_SESSION_TIMEOUT_MINUTES } from '../../config/constants';
import { LogoutType } from '../../models/logout.model';
import { IUser } from '../../models/user.model';
import {useDispatch} from "react-redux";
import {logout} from "../../redux/slices/authSlice";

interface IIdleSessionTimeoutProps {
  account?: IUser;
}

const IdleSessionTimeout = (props: IIdleSessionTimeoutProps) => {
  const dispatch = useDispatch()
  const onIdle = async () => {
    await dispatch(logout({
      logoutType: LogoutType.IDLE_SESSION_TIMEOUT,
      recentURL: window.location.pathname,
      recentSearch: window.location.search,
      recentUsername: props.account?.username,
    })).unwrap()
  }
  const {} = useIdleTimer({
    onIdle,
    timeout: IDLE_SESSION_TIMEOUT_MINUTES * 60 * 1000,
    throttle: 500
  })
  return<></>;
};
export default IdleSessionTimeout;
