import { Button, Input, Space, Form, InputNumber, Select, Modal } from 'antd'
import { LeftCircleOutlined } from "@ant-design/icons"
import { OVERVIEW, PACKAGES } from '../../assets/constants/route_path'
import { formatCurrency } from '../../utils/string-utils'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { apiGetPackageDataSponsor, apiUpdatePackageDataSponsor } from '../../api'
import { toastError, toastSuccess } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { partnerPackageDataSponsorSourceOptions } from '../../assets/constants/default_option'

export default function PackageEditPackagesSponsor() {
    const [modalUpdate, setModalUpdate] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [otpConfirm, setOtpConfirm] = useState<string>('')
    const { setIsLoading } = useLoading()
    const navigate = useNavigate()
    const [formUpdate] = Form.useForm()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const navigateBack = PACKAGES + OVERVIEW + `?previousTab=dataSponsor`

    useEffect(() => {
        const fetchPackage = async () => {
            try {
                setIsLoading(true)
                const resp = await apiGetPackageDataSponsor({ id })
                if (resp.data !== null) {
                    formUpdate.setFieldsValue(resp.data.package)
                }
            } catch (error) {
                console.log(error)
                toastError('Có lỗi xảy ra!')
            } finally {
                setIsLoading(false)
            }
        }
        if (id) {
            fetchPackage()
        }
    }, [id])

    const handleOpenModal = (values: any) => {
        setModalUpdate({ data: values, isOpen: true })
    }

    const handleCloseModal = () => {
        setModalUpdate({ data: null, isOpen: false })
        if (otpConfirm) {
            setOtpConfirm('')
        }
    }

    const handleConfirm = async () => {
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiUpdatePackageDataSponsor({ otpConfirm, package: modalUpdate.data })
            if (resp.status === 200) {
                const data = { otpConfirm, package: modalUpdate.data }
                console.log(data);
                toastSuccess('Đã console.log(values)')
                handleCloseModal()
                navigate(navigateBack)
            } else {
                toastError('Có lỗi xảy ra!')
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 200, marginBottom: 20 }}>
                <Button
                    onClick={() => navigate(navigateBack)}
                    icon={<LeftCircleOutlined />}
                    style={{ background: 'gray', color: 'white' }}
                >
                    Trở lại
                </Button>
            </div>
            <Form
                style={{ maxWidth: 900, width: '90%' }}
                form={formUpdate}
                onFinish={handleOpenModal}
                autoComplete='off'
                labelCol={{ span: 4 }}
                labelAlign="left"
            >
                <Form.Item name='ID' style={{ display: 'none' }} />
                <Form.Item
                    label='Tên gói cước'
                    name='name'
                    rules={[{ required: true, message: 'Vui lòng nhập tên gói cước' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Mã gói cước'
                    name='dataPack'
                    rules={[{ required: true, message: 'Vui lòng nhập mã gói cước' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Giá'
                    name='priceUnit'
                    rules={[{ required: true, message: 'Vui lòng nhập giá' }]}
                >
                    <InputNumber formatter={value => formatCurrency(Number(value))} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label='Phạm vi'
                    name='scope'
                    rules={[{ required: true, message: 'Vui lòng nhập phạm vi' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Nguồn'
                    name='source'
                    rules={[{ required: true, message: 'Vui lòng chọn nguồn' }]}
                >
                    <Select
                        placeholder='Chọn nguồn'
                        options={partnerPackageDataSponsorSourceOptions}
                    />
                </Form.Item>
                <Form.Item
                    label='Loại'
                    name='type'
                    rules={[{ required: true, message: 'Vui lòng nhập loại' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Mô tả'
                    name='description'
                    rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Space>
                        <Button htmlType="submit" type="primary">Cập nhật</Button>
                        <Button htmlType="reset" type="dashed" danger>Clear</Button>
                    </Space>
                </div>
            </Form>
            <Modal title='Cập nhật gói cước' open={modalUpdate.isOpen}
                onCancel={handleCloseModal}
                footer={[
                    <Button onClick={handleConfirm} type='primary'>
                        Xác nhận
                    </Button>,
                ]}>
                <p><span style={{ color: 'red' }}>*</span> Mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            </Modal>
        </div>
    )
}
