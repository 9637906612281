import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector} from "react-redux";
import {authSelector} from "../../redux/slices/authSlice";
import {AUTH_LOGIN} from "../../assets/constants/route_path";

const HomePage = () => {
    const authState = useSelector(authSelector)
    console.log("authState", authState)
    const navigate = useNavigate()
    useEffect(() => {
        if (!authState.isAuthenticated) {
            navigate(AUTH_LOGIN)
        }
    }, [authState, navigate])
    return <div >HomePage</div>
}
export default HomePage