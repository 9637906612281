import React from "react";
import { LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, MenuProps, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import { logout} from "../../redux/slices/authSlice";
import {AUTH_LOGIN, PROFILE} from "../../assets/constants/route_path";
import {IUser} from "../../models/user.model";
import {LogoutType} from "../../models/logout.model";
import {useWindowSize} from "../../hook";
import translate from "../../constants/translate";
type HeaderProps = {
    profile?: IUser;
    collapsed: boolean
};
const newHeaderMenuItems = ({
                                onLogoutCb,
                                navigateCb,
                            }: {
    onLogoutCb: () => void;
    navigateCb: any;
}): MenuProps["items"] => {
    return [
        {
            key: "1",
            onClick:()=>navigateCb(PROFILE),
            label: (
                <Space>
                    <UserOutlined />
                    <span>{translate.profile}</span>
                </Space>
            ),
        },
        {
            key: "2",
            onClick: onLogoutCb,
            label: (
                <Space>
                    <LogoutOutlined />
                        <span>{translate.logout}</span>
                </Space>
            ),
        },
    ];
};

const LayoutHeader: React.FunctionComponent<HeaderProps> = (props) => {
    const {profile,collapsed} = props
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleLogout = async () => {
        await dispatch(logout({logoutType: LogoutType.USER_LOGOUT})).unwrap()
        navigate(AUTH_LOGIN, { replace: true });
    };
    const size = useWindowSize()
    const widthMenu =  collapsed ? 80 : 250
    return (
        <Layout.Header
            style={{
                    backgroundColor: "white",
                    borderBottom: "1px solid rgba(5, 5, 5, 0.06)",
                    paddingLeft: 12,
                    paddingRight: 20,
                    height: 64,
                    position: 'fixed',
                    zIndex: 1000,
                    width: size.width - widthMenu
                }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Dropdown
                    menu={{
                        items: newHeaderMenuItems({
                            onLogoutCb: handleLogout,
                            navigateCb:navigate,
                        }),
                    }}
                    trigger={["click"]}
                    overlayStyle={{ width: "200px", height: "200px" }}
                >
                    <Space style={{ cursor: "pointer" }}>
                            <>
                                <Typography.Text strong>
                                    {translate.hi}, {profile?.username}
                                </Typography.Text>
                            </>
                        <Avatar icon={<SettingOutlined />} />
                    </Space>
                </Dropdown>
            </div>
        </Layout.Header>
    );
};

export default LayoutHeader;
