import {
  createBrowserRouter,
  matchRoutes,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  NotFound,
  LoginPage,
  AuthPage,
  ProfilePage,
  Home,
  CustomerPage,
  TransactionPage,
  NetworkPage,
  PartnerWalletPage,
  PackagePage,
  RevenuePage,
  PackagePartner,
  PackageCreatePartnerSponsor,
  PackageCreatePartnerCode,
  PackageCreatePackagesSponsor,
  PackageCreatePackagesCode,
  SystemPage,
  PackageEditPackagesSponsor,
  PackageEditPackagesCode,
  PackageEditPartnerSponsor,
  PackageEditPartnerCode,
  PackageCreatePartnerSponsorPromotion,
  PackageEditPartnerSponsorPromotion,
  RevenueReport,
} from "../pages";
import {
  AUTH_LOGIN,
  CUSTOMERS,
  HOME,
  PROFILE,
  TRANSACTIONS, NETWORKS,
  PARTNERWALLET,
  PACKAGES,
  REVENUES,
  OVERVIEW,
  DETAIL,
  CREATE_PACKAGES_SPONSOR,
  CREATE_PARTNET_SPONSOR,
  CREATE_PARTNET_CODE,
  CREATE_PACKAGES_CODE,
  SYSTEM,
  EDIT_PACKAGES_SPONSOR,
  EDIT_PACKAGES_CODE,
  EDIT_PARTNET_SPONSOR,
  EDIT_PARTNET_CODE,
  CREATE_PARTNET_SPONSOR_PROMOTION,
  EDIT_PARTNET_SPONSOR_PROMOTION,
  REPORT,
} from "../assets/constants/route_path";
import Layout from "../layout";
import PrivateRoute from "../components/private-route";
import { DashboardLayout } from "../pages/dashbooard";
import PermUtils from "../utils/perm-utils";

export const routes = [
  {
    path: "/auth",
    element: <AuthPage />,
    children: [
      {
        path: AUTH_LOGIN,
        element: <LoginPage />,
      },
    ],
  },

  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: HOME,
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        ),
      },
      {
        path: "/user",
        element: (
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: CUSTOMERS,
        element: (
          <PrivateRoute>
            <CustomerPage />
          </PrivateRoute>
        ),
      },
      {
        path: PARTNERWALLET,
        element: (
          <PrivateRoute>
            <PartnerWalletPage />
          </PrivateRoute>
        ),
      },
      {
        path: NETWORKS,
        element: (
          <PrivateRoute>
            <NetworkPage />
          </PrivateRoute>
        ),
      },
      {
        path: TRANSACTIONS,
        element: (
          // <PrivateRoute
          //     hasAnyFunctions={[PermUtils.canGetLisTransactionAdminResource]}
          // >
          <TransactionPage />
          // </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + OVERVIEW,
        element: (
          <PrivateRoute>
            <PackagePage />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + DETAIL,
        element: (
          <PrivateRoute>
            <PackagePartner />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + DETAIL + CREATE_PARTNET_SPONSOR,
        element: (
          <PrivateRoute>
            <PackageCreatePartnerSponsor />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + DETAIL + CREATE_PARTNET_CODE,
        element: (
          <PrivateRoute>
            <PackageCreatePartnerCode />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + DETAIL + CREATE_PARTNET_SPONSOR_PROMOTION,
        element: (
          <PrivateRoute>
            <PackageCreatePartnerSponsorPromotion />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + OVERVIEW + CREATE_PACKAGES_SPONSOR,
        element: (
          <PrivateRoute>
            <PackageCreatePackagesSponsor />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + OVERVIEW + CREATE_PACKAGES_CODE,
        element: (
          <PrivateRoute>
            <PackageCreatePackagesCode />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + OVERVIEW + EDIT_PACKAGES_SPONSOR,
        element: (
          <PrivateRoute>
            <PackageEditPackagesSponsor />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + OVERVIEW + EDIT_PACKAGES_CODE,
        element: (
          <PrivateRoute>
            <PackageEditPackagesCode />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + DETAIL + EDIT_PARTNET_SPONSOR_PROMOTION,
        element: (
          <PrivateRoute>
            <PackageEditPartnerSponsorPromotion />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + DETAIL + EDIT_PARTNET_SPONSOR,
        element: (
          <PrivateRoute>
            <PackageEditPartnerSponsor />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGES + DETAIL + EDIT_PARTNET_CODE,
        element: (
          <PrivateRoute>
            <PackageEditPartnerCode />
          </PrivateRoute>
        ),
      },
      {
        path: REVENUES + OVERVIEW,
        element: (
          <PrivateRoute>
            <RevenuePage />
          </PrivateRoute>
        ),
      },
      {
        path: REVENUES + REPORT,
        element: (
          <PrivateRoute>
            <RevenueReport />
          </PrivateRoute>
        ),
      },
      {
        path: SYSTEM,
        element: (
          <PrivateRoute>
            <SystemPage />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE,
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export const useMatchedRoute = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  if (!matchedRoutes || matchedRoutes.length === 0) {
    return undefined;
  }

  // Sort by accuracy desc
  matchedRoutes.reverse();

  for (let matchedRoute of matchedRoutes) {
    const matchedRoutePath = matchedRoute.route.path;
    // index route will not contain path attr -> get next route (parent route)
    if (matchedRoutePath) {
      return matchedRoutePath;
    }
  }

  return undefined;
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routes);
