import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Select, Space, TableProps } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import CustomTable from '../../components/table';
import defaultParams from '../../assets/constants/default_params';
import { apiGetListPackageDataCodes } from "../../api";
import { useLoading } from '../../context/LoadingContext';
import { formatCurrency } from '../../utils/string-utils';
import { Link } from 'react-router-dom';
import { CREATE_PACKAGES_CODE, EDIT_PACKAGES_CODE, OVERVIEW, PACKAGES } from '../../assets/constants/route_path';
import ViewPackagesCode from './ViewPackagesCode';
import { formatDate } from '../../utils/entity-utils';
import { partnerPackageDataCodeSourceOptions } from '../../assets/constants/default_option';

export default function TabPackagesCode() {
    const [codes, setCodes] = useState<any>({})
    const [source, setSource] = useState('')
    const [currentPageCode, setCurrentPageCode] = useState<number>(defaultParams.PAGE)
    const [pageSizeCode, setPageSizeCode] = useState<number>(defaultParams.LIMIT)
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()

    const codeColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: "STT",
            render: (value, recode, index) => ++index,
        },
        {
            key: 'dataCodeModelId',
            title: 'DataCodeModelId',
            dataIndex: 'dataCodeModelId',
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'dataCode',
            title: "Mã gói cước",
            dataIndex: 'dataCode',
        },
        {
            key: 'priceUnit',
            title: "Giá",
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit)}
                </span>
            ),
        },
        {
            key: 'source',
            title: "Nguồn",
            dataIndex: 'source',
        },
        {
            key: 'type',
            title: "Loại",
            dataIndex: 'type',
        },
        {
            key: 'status',
            title: "Trạng thái",
            dataIndex: 'status',
        },
        {
            key: 'CreatedAt',
            title: "Thời gian tạo",
            dataIndex: 'CreatedAt',
            render: CreatedAt => formatDate(CreatedAt)
        },
        {
            key: 'action',
            render: data => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    <Link to={PACKAGES + OVERVIEW + EDIT_PACKAGES_CODE + `?id=${data.ID}`}>
                        <Button
                            onClick={() => setModalView({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            style={{ background: '#ae7700', color: 'white' }}
                        >
                            Cập nhật
                        </Button>
                    </Link>
                </Space>
            )
        }
    ]

    const fetchDataCode = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCodes = await apiGetListPackageDataCodes({ source })
            if (resCodes.data.dataCodes != null) {
                if (source) {
                    const listSponsor = {} as any
                    const listSponsorBySource = resCodes.data?.dataCodes?.filter((item: any) => item.source === source)
                    listSponsor.dataCodes = listSponsorBySource
                    listSponsor.total = listSponsorBySource?.length
                    setCodes(listSponsor)
                } else {
                    setCodes(resCodes.data)
                }
            } else {
                setCodes({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [currentPageCode, pageSizeCode, source])

    useEffect(() => {
        fetchDataCode()
    }, [fetchDataCode])

    return (
        <>
            <Form
                style={{ display: 'flex', gap: 10 }}
                onFinish={values => setSource(values.source || '')}
                autoComplete='off'
            >
                <Form.Item
                    label='Chọn nguồn'
                    name='source'
                >
                    <Select
                        style={{ width: 200 }}
                        placeholder="Chọn nguồn"
                        defaultValue={''}
                        options={[
                            { label: 'Tất cả', value: '' },
                            ...partnerPackageDataCodeSourceOptions,
                        ]}
                    />
                </Form.Item>
                <Button htmlType='submit' type="primary" style={{ width: 100 }}>Tìm kiếm</Button>
                <Button htmlType='reset' onClick={() => setSource('')} danger style={{ width: 100 }}>Clear</Button>
            </Form>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Link to={PACKAGES + OVERVIEW + CREATE_PACKAGES_CODE}>
                    <Button type='primary'>Thêm gói cước</Button>
                </Link>
            </div>
            <CustomTable
                namePage='dataCodes'
                columns={codeColumns}
                dataSource={codes?.dataCodes}
                pageSize={pageSizeCode}
                setPageSize={setPageSizeCode}
                total={codes.total}
                currentPage={currentPageCode}
                setCurrentPage={setCurrentPageCode}
            />
            <ViewPackagesCode
                modalView={modalView}
                setModalView={setModalView}
            />
        </>
    )
}
