import { ToastOptions, toast } from "react-toastify";

const toastOptions: ToastOptions = {
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const toastSuccess = (text: string, options?: Partial<ToastOptions>) => {
  toast.success(text, { ...toastOptions, ...options });
};

export const toastError = (text: string, options?: Partial<ToastOptions>) => {
  toast.error(text, { ...toastOptions, ...options });
};
