import { useCallback, useEffect, useState } from 'react'
import { useLoading } from '../../context/LoadingContext'
import { apiDownloadDataCodeReportDaily, apiDownloadDataSponsorReportDaily, apiDownloadRevenueReportDaily, apiGetListPartner } from '../../api'
import { Button, ConfigProvider, DatePicker, Form, Input, Select } from 'antd'
import { DownloadOutlined, UndoOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import vi_VN from 'antd/es/locale/vi_VN'
import { reportType } from '../../assets/constants/default_option'
import moment from 'moment'
import { toastError, toastSuccess } from '../../utils/toast'
import { saveAs } from 'file-saver'
dayjs.locale('vi')

export default function Report() {
    const [partnerOptions, setPartnerOptions] = useState<any>([])
    const [date, setDate] = useState<any>()
    const { setIsLoading } = useLoading()

    const fetchPartners = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListPartner()
            if (resp.data?.partners != null) {
                const listPartnerOptions = resp.data.partners.map((partner: any) => (
                    { label: partner.name, value: partner.cpCode }
                ))
                setPartnerOptions(listPartnerOptions)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchPartners()
    }, [fetchPartners])

    const handleDownload = async (values: any) => {
        if (!date) {
            toastError('Vui lòng chọn ngày báo cáo!')
            return
        }

        try {
            setIsLoading(true)
            const { cpCode, type, otpConfirm } = values
            const reqDownload = { cpCode, otpConfirm, date: date?.format('YYYY-MM-DD') }
            switch (type) {
                case 'revenue':
                    const respRevenue = await apiDownloadRevenueReportDaily(reqDownload)
                    if (respRevenue.status === 200) {
                        const blob = new Blob([respRevenue.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        saveAs(blob, `${cpCode}_${type}_report_daily_${reqDownload.date}`)
                        toastSuccess('Đã xuất báo cáo danh thu!')
                    }
                    return
                case 'data_sponsor':
                    const respDataSponsor = await apiDownloadDataSponsorReportDaily(reqDownload)
                    if (respDataSponsor.status === 200) {
                        const blob = new Blob([respDataSponsor.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        saveAs(blob, `${cpCode}_${type}_report_daily_${reqDownload.date}`)
                        toastSuccess('Đã xuất báo cáo data sponsor!')
                    }
                    return
                case 'data_code':
                    const respDataCode = await apiDownloadDataCodeReportDaily(reqDownload)
                    if (respDataCode.status === 200) {
                        const blob = new Blob([respDataCode.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        saveAs(blob, `${cpCode}_${type}_report_daily_${reqDownload.date}`)
                        toastSuccess('Đã xuất báo cáo data code!')
                    }
                    return
                default:
                    return
            }
        } catch (error) {
            console.log(error)
            toastSuccess('Có lỗi xảy ra! Vui lòng kiểm tra lại!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <h2 style={{ textAlign: 'center' }}>Báo cáo</h2>
            <Form
                onFinish={handleDownload}
                style={{ maxWidth: 500, margin: '0 auto' }}
                labelCol={{ span: 6 }}
                labelAlign="left"
            >
                <Form.Item label='Đối tác' name='cpCode'
                    rules={[{ required: true, message: 'Vui lòng chọn đối tác' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn đối tác"
                        options={partnerOptions}
                        filterOption={(input: string, option: any) => (
                            option.label.toLowerCase().includes(input.toLowerCase())
                        )}
                    />
                </Form.Item>
                <Form.Item label='Loại báo cáo' name='type'
                    rules={[{ required: true, message: 'Vui lòng chọn loại báo cáo' }]}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Chọn loại báo cáo"
                        options={reportType}
                    />
                </Form.Item>
                <Form.Item label='Ngày báo cáo' name='date' initialValue={moment()}
                    rules={[{ required: true, message: 'Vui lòng chọn ngày báo cáo' }]}>
                    <ConfigProvider locale={vi_VN}>
                        <DatePicker
                            onChange={value => setDate(value)}
                            format='DD/MM/YYYY'
                            style={{ width: '100%' }}
                            placeholder='Chọn ngày báo cáo'
                            picker='date'
                        />
                    </ConfigProvider>
                </Form.Item>
                <Form.Item label='Mã xác nhận' name='otpConfirm'
                    rules={[{ required: true, message: 'Vui lòng chọn loại báo cáo' }]}>
                    <Input />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'end', gap: 10 }}>
                    <Button htmlType='submit' type='primary' icon={<DownloadOutlined />}>Xuất báo cáo</Button>
                    <Button htmlType='reset' onClick={() => setDate(null)} danger icon={<UndoOutlined />}>Làm mới</Button>
                </div>
            </Form>
        </div>
    )
}
