import { Tabs, TabsProps } from 'antd';
import { useSearchParams } from 'react-router-dom';
import TabPackagesSponsor from './TabPackagesSponsor';
import TabPackagesCode from './TabPackagesCode';

export default function Packages() {
    const [searchParams] = useSearchParams()
    const previousTab = searchParams.get('previousTab')

    const itemsTab: TabsProps['items'] = [
        {
            key: 'dataSponsor',
            label: 'Data sponsor',
            children: <TabPackagesSponsor />,
        },
        {
            key: 'dataCode',
            label: 'Data code',
            children: <TabPackagesCode />,
        },
    ];

    return (
        <div>
            <Tabs defaultActiveKey={previousTab || 'dataSponsor'} items={itemsTab} />
        </div>
    )
}
