import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Button, notification, InputNumber } from 'antd';
import { apiPostRefundForPartner } from '../../api';
import { RefundForPartnerRequest } from '../../interfaces/request-params.interface';
import { useLoading } from '../../context/LoadingContext';
import { formatCurrency } from '../../utils/string-utils';

type Props = {
    isModalVisible: boolean
    onClose: (requireSync?: boolean) => void
    cpCode?: string
}


const initial = {
    cpCode: '',
    refund: 0,
    description: '',
    OTPConfirm: '',
}


const rules = {
    amount: [
        { required: true, message: 'Vui lòng nhập số tiền' },
        {
            pattern: /^\d+$/, // Matches positive integers
            validator: (_: any, value: any) => {
                if (Number(value) <= 0) {
                    return Promise.reject('Số tiền phải lớn hơn 0');
                }
                return Promise.resolve();
            },
        },
    ],
    OTPConfirm: [
        { required: true, message: 'Vui lòng nhập mã code đăng nhập' },
        { min: 6,  message: 'Mã code đăng nhập phải có 6 ký tự' },
    ],
};

export default function RefundForPartner({ isModalVisible, onClose,cpCode }: Props) {
        // Loading context 
        const { setIsLoading } = useLoading();

    const [formData, setFormData] = useState<RefundForPartnerRequest>(initial);
    const [form] = Form.useForm();

    useEffect(()=>{
        setFormData({...formData,cpCode:cpCode ?? ''})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cpCode])

    const openNotification = (type: 'success' | 'info' | 'warning' | 'error', message: string, description?: string) => {
        notification[type]({
          message,
          description,
        });
      };
    

    const handleChange = (event: any) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async () => {
        Modal.confirm({
            title: 'Xác nhận hoàn tiền',
            content: `Bạn có muốn hoàn ${formatCurrency(formData.refund)} cho đối tác ${formData.cpCode}`,
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            onOk: async () => {
                try {
                    // Implement logic to submit the form data (e.g., API call)
                    setIsLoading(true)
                    console.log('Submitted data:', formData);
                    await apiPostRefundForPartner({...formData,refund:Number(formData.refund)})
                    onClose(true);
                    setIsLoading(false)
                    openNotification('success','Hoàn tiền thành công')
                } catch (error) {
                    openNotification('error','Vui lòng kiểm tra lại thông tin','Hãy thử đăng nhập lại')
                    setIsLoading(false)
                }
            },
        })
    };

    const handleClose = () =>{
        setFormData({...initial,cpCode:cpCode ?? ''})
        form.resetFields();
        onClose()
    }

    return (
        <div>
            <Modal
                title="Hoàn tiền cho đối tác"
                visible={isModalVisible}
                onCancel={handleClose}
                footer={[]}
            >
                <Form layout="vertical" onFinish={handleSubmit} form={form}>
                    <Form.Item label="Mã đối tác (CP Code)">
                        <Input name="cpCode" value={formData.cpCode} onChange={handleChange} disabled/>
                    </Form.Item>
                    <Form.Item label="Số tiền hoàn" rules={rules.amount} name="refund">
                        <InputNumber
                            style={{ width: '100%' }}
                            formatter={(value) => {
                                return `${formatCurrency(Number(value))}`
                            }}
                            name="refund"
                            onChange={(value) => {
                                setFormData({ ...formData, refund: Number(value) })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Mô tả">
                        <Input.TextArea name="description" value={formData.description} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item label="Mã OTP xác nhận" name="OTPConfirm" rules={rules.OTPConfirm}>
                        <Input.Password name="OTPConfirm" value={formData.OTPConfirm} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Xác nhận
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
