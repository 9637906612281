// Auth
const AUTH_LOGIN = "/auth/login";
const HOME = "/";

const PROFILE = "/profile";
const USER = "/user";
const CUSTOMERS = "/customers";
const PARTNERWALLET = "/partner-wallet";
const PACKAGES = "/packages";
const REVENUES = '/revenues'
const SYSTEM = '/system'
const REPORT = '/report'


const NETWORKS = "/network-provider";
const SHARKS = "/sharks";

const OVERVIEW = '/overview';
const DETAIL = '/detail';
const CREATE_PARTNET_SPONSOR = '/create-partner-sponsor';
const CREATE_PARTNET_CODE = '/create-partner-code';
const CREATE_PARTNET_SPONSOR_PROMOTION = '/create-partner-sponsor-promotion';
const CREATE_PACKAGES_SPONSOR = '/create-packages-sponsor';
const CREATE_PACKAGES_CODE = '/create-packages-code';
const EDIT_PACKAGES_SPONSOR = '/edit-packages-sponsor';
const EDIT_PACKAGES_CODE = '/edit-packages-code';
const EDIT_PARTNET_SPONSOR = '/edit-partner-sponsor';
const EDIT_PARTNET_SPONSOR_PROMOTION = '/edit-partner-sponsor-promotion';
const EDIT_PARTNET_CODE = '/edit-partner-code';

const TRANSACTIONS = "/transactions";
const BANKS = "/banks";
const VOTES = "/votes";
const ADMIN = "/admin";
const ADMIN_EDIT = "/admin/:id";

const ROLES = "/roles";
const ROLE_CREATE = '/roles/create'
const ROLE_EDIT = "/roles/:id";
export {
  AUTH_LOGIN,
  PROFILE,
  HOME,
  USER,
  CUSTOMERS,
  SHARKS,
  VOTES,
  TRANSACTIONS,
  BANKS,
  ADMIN,
  ADMIN_EDIT,
  ROLES,
  ROLE_CREATE,
  ROLE_EDIT,
  NETWORKS,
  PARTNERWALLET,
  PACKAGES,
  REVENUES,
  OVERVIEW,
  DETAIL,
  CREATE_PARTNET_SPONSOR,
  CREATE_PARTNET_CODE,
  CREATE_PARTNET_SPONSOR_PROMOTION,
  CREATE_PACKAGES_SPONSOR,
  CREATE_PACKAGES_CODE,
  EDIT_PACKAGES_SPONSOR,
  EDIT_PACKAGES_CODE,
  EDIT_PARTNET_SPONSOR,
  EDIT_PARTNET_SPONSOR_PROMOTION,
  EDIT_PARTNET_CODE,
  SYSTEM,
  REPORT,
};
