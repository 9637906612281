import { useCallback, useEffect, useState } from "react"
import { apiGetAccountAffApiCurrent, apiGetAccountDataSponsorCurrent, apiGetProviderCurrent, apiGetStatusMaintain } from "../../api"
import { Button, Card, Tag } from "antd"
import { WHILELIST_ACCOUNT } from "../../config/constants"
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import ModalMaintain from "./ModalMaintain";
import ModalAccountDataSponsor from "./ModalAccountDataSponsor";
import ModalAccountAffapi from "./ModalAccountAffapi";
import ModalProvider from "./ModalProvider";

export default function SystemPage() {
    const [canUpdate, setCanUpdate] = useState(false)
    const authState = useSelector(authSelector);
    const { account } = authState;
    const [maintainValue, setMaintainValue] = useState('');
    const [providerValue, setProviderValue] = useState('');
    const [modalMaintain, setModalMaintain] = useState(false);
    const [accountDataSponsorValue, setAccountDataSponsorValue] = useState('');
    const [accountAffapiValue, setAccountAffapiValue] = useState('');
    const [modalAccountDataSponsor, setModalAccountDataSponsor] = useState(false);
    const [modalAccountAffapi, setModalAccountAffapi] = useState(false);
    const [modalProvider, setModalProvider] = useState(false);

    const fetchMaintain = useCallback(async () => {
        try {
            const resp = await apiGetStatusMaintain()
            if (resp.data?.value !== null) {
                setMaintainValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchProvider = useCallback(async () => {
        try {
            const resp = await apiGetProviderCurrent()
            if (resp.data?.value !== null) {
                setProviderValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchAccountDataSponsor = useCallback(async () => {
        try {
            const resp = await apiGetAccountDataSponsorCurrent()
            if (resp.data?.value !== null) {
                setAccountDataSponsorValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchAccountAffapi = useCallback(async () => {
        try {
            const resp = await apiGetAccountAffApiCurrent()
            if (resp.data?.value !== null) {
                setAccountAffapiValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const checkWhilelistAccount = useCallback(() => {
        const listWhilelistAccounts = WHILELIST_ACCOUNT?.split(',')
        if (listWhilelistAccounts?.includes(account.username)) {
            setCanUpdate(true)
        }
    }, [])

    useEffect(() => {
        checkWhilelistAccount()
        fetchMaintain()
        fetchProvider()
        fetchAccountDataSponsor()
        fetchAccountAffapi()
    }, [])

    const renderTag = (value: string, isActive = true) => (
        <Tag color={isActive ? "green" : "red"}>
            <h3 style={{ margin: 0, padding: 4 }}>{value}</h3>
        </Tag>
    )

    const renderMaintain = () => {
        if (maintainValue === 'true') {
            return renderTag('Đang bảo trì', false)
        }
        return renderTag('Đang hoạt động')
    }
    const renderProvider = () => {
        if (providerValue === 'ds_postpaid') {
            return renderTag('Affapi')
        }
        return renderTag('DataSponsor')
    }

    const renderAccountDataSponsor = () => {
        switch (accountDataSponsorValue) {
            case 'data_sponsor':
                return renderTag('SANN')
            case 'datasponsor_zynet':
                return renderTag('Zynet')
            default:
                return renderTag('Không tìm thấy', false)
        }
    }

    const renderAccountAffapi = () => {
        switch (accountAffapiValue) {
            case 'aff_sann':
                return renderTag('SANN')
            case 'aff_zynet':
                return renderTag('Zynet')
            default:
                return renderTag('Không tìm thấy', false)
        }
    }


    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20, flexWrap: 'wrap', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <Card title="Trạng thái hệ thống" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderMaintain()}
                            {canUpdate && <Button onClick={() => setModalMaintain(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Nhà cung cấp cho hệ thống hiện tại" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderProvider()}
                            {canUpdate && <Button onClick={() => setModalProvider(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Tài khoản data sponsor đang hoạt động" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderAccountDataSponsor()}
                            {canUpdate && <Button onClick={() => setModalAccountDataSponsor(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Tài khoản affapi đang hoạt động" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderAccountAffapi()}
                            {canUpdate && <Button onClick={() => setModalAccountAffapi(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
            </div>
            <ModalMaintain
                isOpen={modalMaintain}
                setIsOpen={setModalMaintain}
                data={maintainValue}
                fetchData={fetchMaintain}
            />
            <ModalProvider
                isOpen={modalProvider}
                setIsOpen={setModalProvider}
                data={providerValue}
                fetchData={fetchProvider}
            />
            <ModalAccountDataSponsor
                isOpen={modalAccountDataSponsor}
                setIsOpen={setModalAccountDataSponsor}
                data={accountDataSponsorValue}
                fetchData={fetchAccountDataSponsor}
            />
            <ModalAccountAffapi
                isOpen={modalAccountAffapi}
                setIsOpen={setModalAccountAffapi}
                data={accountAffapiValue}
                fetchData={fetchAccountAffapi}
            />
        </div>
    )
}
