import { useCallback, useEffect, useState } from 'react';
import { TableProps, Dropdown, Modal, InputNumber, Button, Input, Form } from 'antd';
import { CaretDownOutlined, EditFilled } from "@ant-design/icons";
import translate from '../../constants/translate';
import CustomTable from '../../components/table';
import defaultParams from '../../assets/constants/default_params';
import { toastError, toastSuccess } from '../../utils/toast';
import { formatCurrency } from '../../utils/string-utils';
import {apiGetListNetworkProvider} from "../../api";
import { useLoading } from '../../context/LoadingContext';

export default function Networks() {
  const [customers, setCustomers] = useState<any>({})
  const [dataModal, setDataModal] = useState({ isOpen: false, type: 0, userId: '', username: '' })
  const [amount, setAmount] = useState<number>(0)
  const [desc, setDesc] = useState<string>('')
  const [errMessage, setErrMessage] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
  const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
  const [usernameKey, setUsernameKey] = useState<string>('')
  const [nameKey, setNameKey] = useState<string>('')
  const [startDate, setStartDate] = useState<string>(defaultParams.START_DATE)
  const [endDate, setEndDate] = useState<string>(defaultParams.END_DATE)
  const [form] = Form.useForm()

  // Loading context 
  const { setIsLoading } = useLoading();

  const columns: TableProps<any>['columns'] = [
    {
      key: 'id',
      title: "Id",
      dataIndex: 'id',
    },
    {
      key: 'cpCode',
      title: "cpCode",
      dataIndex: 'cpCode',
    },
    {
      key: 'name',
      title: "name",
      dataIndex: 'name',
    },
    {
      key: 'balance',
      title: "balance",
      dataIndex: 'balance',
      render:(balance)=> balance===0?"N/A":formatCurrency(balance)
    },
    {
      key: 'isActive',
      title: "isActive",
      dataIndex: 'isActive',
      render:(isActive)=> isActive?"Active": "Off"
    },
    {
      key: 'proxyAddress',
      title: "proxyAddress",
      dataIndex: 'proxyAddress',
    },
]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderAction = (data: any) => {
    const items = [
      {
        label: translate.topUp,
        key: "top-up",
        icon: <EditFilled style={{ color: "green" }} />,
        onClick: () => setDataModal({
          isOpen: true, type: 1,
          userId: data.user.user_id,
          username: data.user.username
        }),
      },
      {
        label: translate.withdraw,
        key: "withdraw",
        icon: <EditFilled style={{ color: "red" }} />,
        onClick: () => setDataModal({
          isOpen: true, type: 2,
          userId: data.user.user_id,
          username: data.user.username
        }),
      },
    ];

    const filteredItems = items?.filter((item) => item);

    return (
      <Dropdown.Button
        trigger={["click"]}
        type='primary'
        icon={<CaretDownOutlined />}
        menu={{ items: filteredItems }}>
        {translate.topUpWithdraw}
      </Dropdown.Button>
    );
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const resCustomers = await apiGetListNetworkProvider()
      console.log("resCustomers", resCustomers.data.networks)
      if (resCustomers.data.networks != null) {
        setCustomers(resCustomers.data)
      } else {
        setCustomers({})
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, usernameKey, nameKey, startDate, endDate])

  useEffect(() => {
    fetchData()
  }, [fetchData])



  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSearch = async ({ username, name, date }: any) => {
    if (date) {
      setStartDate(date[0].format(defaultParams.FORMAT_TIME))
      setEndDate(date[1].format(defaultParams.FORMAT_TIME))
    }
    setCurrentPage(defaultParams.PAGE)
    setUsernameKey(username)
    setNameKey(name)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClearSearch = async () => {
    setStartDate(defaultParams.START_DATE)
    setEndDate(defaultParams.END_DATE)
    setCurrentPage(defaultParams.PAGE)
    setUsernameKey('')
    setNameKey('')
    form.resetFields()
  }

  return (
    <div>
      <CustomTable
        namePage='Tài khoản'
        columns={columns}
        dataSource={customers?.networks}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={customers.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      </div>
  )
}
