/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "./main-layout";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, getSession } from "../redux/slices/authSlice";
import { AUTH_LOGIN } from "../assets/constants/route_path";
import { useEffect } from "react";
import { USER_PROFILE_KEY } from "../config/constants";
import { getDefaultAuthenticatedRoute } from "../utils/app-utils";
import Loading from "../components/loading";
import { useLoading } from "../context/LoadingContext";

export default function Layout() {
  const authState = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleGetSession = async () => {
    console.log("handleGetSession");
    try {
      const sessionResp = await dispatch(getSession()).unwrap();
      console.log("sessionResp", sessionResp);
    } catch (error) {
      console.log(
        "🚀 ~ : Layout ~ line 55 ~ redirectLoginPasswordless ~ error",
        error,
      );
    } finally {
      // setLoading(false)
    }
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      navigate(AUTH_LOGIN);
    } else {
      const cacheUser: any = localStorage?.getItem(USER_PROFILE_KEY);
      if (!cacheUser) {
        handleGetSession();
      }
    }
  }, [authState.isAuthenticated, navigate]);

  useEffect(() => {
    if (location.pathname === "/" && authState.isAuthenticated) {
      navigate(getDefaultAuthenticatedRoute(authState?.account))
    }
  }, [location.pathname]);

  const { isLoading } = useLoading();

  return (
    <div id='wrapper'>
      <MainLayout>
        {isLoading && <Loading />}
        <div style={{ padding: "16px" }} className='w-100 p-3'>
          <Outlet />
        </div>
      </MainLayout>
    </div>
  );
}
