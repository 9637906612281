import React from "react";
import { Layout } from "antd";
import translate from "../../constants/translate";
const LayoutFooter: React.FunctionComponent = () => (
    <Layout.Footer
        style={{
            textAlign: "center",
            backgroundColor: "white",
            borderTop: "1px solid rgba(5, 5, 5, 0.06)"
        }}
    >
        <p>{translate.copyright}</p>
    </Layout.Footer>
);

export default LayoutFooter;