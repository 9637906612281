import { useState } from "react"
import { useLoading } from "../../context/LoadingContext"
import { toastError, toastSuccess } from "../../utils/toast"
import { Button, Form, Input, InputNumber, Modal, Popconfirm, Select, Space, Table, TableProps } from "antd"
import { LeftCircleOutlined } from "@ant-design/icons"
import { formatCurrency } from "../../utils/string-utils"
import { useNavigate } from "react-router-dom"
import { apiAddPackageDataCodes } from "../../api"
import { OVERVIEW, PACKAGES } from "../../assets/constants/route_path"
import { partnerPackageDataCodeSourceOptions } from "../../assets/constants/default_option"

export default function PackageCreatePackages() {
    const { setIsLoading } = useLoading()
    const [listAddCodes, setListAddCodes] = useState<any>([])
    const navigate = useNavigate()
    const [formCreate] = Form.useForm()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [otpConfirm, setOtpConfirm] = useState<string>('')

    const handleAddPackage = (values: any) => {
        try {
            setIsLoading(true)
            const { dataCodeModelId, name, dataCode, priceUnit, type, source, description } = values
            setListAddCodes([...listAddCodes, { dataCodeModelId, name, dataCode, priceUnit, type, source, description }])
            formCreate.resetFields()
            toastSuccess('Đã thêm gói cước vào danh sách!')
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const codeColumns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            render: (_text, _record, index) => index + 1,
        },
        {
            key: 'dataCodeModelId',
            title: "DataCodeModelId",
            dataIndex: 'dataCodeModelId',
        },
        {
            key: 'name',
            title: "Tên gói cước",
            dataIndex: 'name',
        },
        {
            key: 'dataCode',
            title: "Mã gói cước",
            dataIndex: 'dataCode',
        },
        {
            key: 'priceUnit',
            title: "Giá",
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit)}
                </span>
            ),
        },
        {
            key: 'type',
            title: "Loại",
            dataIndex: 'type',
        },
        {
            key: 'source',
            title: "Nguồn",
            dataIndex: 'source',
        },
        {
            key: 'description',
            title: "Mô tả",
            dataIndex: 'description',
        },
        {
            key: 'action',
            render: (_text, _record, index) => (
                <Popconfirm
                    title="Xoá gói cước"
                    description="Xoá gói cước khỏi danh sách?"
                    onConfirm={() => {
                        const newList = listAddCodes.filter((_: any, i: any) => i !== index)
                        setListAddCodes(newList)
                    }}
                    okText="Xác nhận"
                    cancelText="Huỷ bỏ"
                >
                    <Button type="primary" danger>Xoá</Button>
                </Popconfirm>
            )
        }
    ]

    const handleAddPackageDataCodes = async () => {
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiAddPackageDataCodes({
                dataCodes: listAddCodes,
                otpConfirm: otpConfirm
            })
            if (resp.status === 200) {
                toastSuccess('Thêm gói cước thành công!')
                setListAddCodes([])
                setIsOpenModal(false)
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 200, marginBottom: 20 }}>
                <Button
                    onClick={() => navigate(PACKAGES + OVERVIEW + `?previousTab=dataCode`)}
                    icon={<LeftCircleOutlined />}
                    style={{ background: 'gray', color: 'white' }}
                >
                    Trở lại
                </Button>
            </div>
            <Form
                style={{ maxWidth: 900, width: '90%' }}
                form={formCreate}
                onFinish={handleAddPackage}
                autoComplete='off'
                labelCol={{ span: 4 }}
                labelAlign="left"
            >
                <Form.Item
                    label='DataCodeModelId'
                    name='dataCodeModelId'
                    rules={[{ required: true, message: 'Vui lòng nhập DataCodeModelId' }]}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label='Tên gói cước'
                    name='name'
                    rules={[{ required: true, message: 'Vui lòng nhập tên gói cước' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Mã gói cước'
                    name='dataCode'
                    rules={[{ required: true, message: 'Vui lòng nhập mã gói cước' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Giá'
                    name='priceUnit'
                    rules={[{ required: true, message: 'Vui lòng nhập giá' }]}
                >
                    <InputNumber formatter={value => formatCurrency(Number(value))} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label='Loại'
                    name='type'
                    rules={[{ required: true, message: 'Vui lòng nhập loại' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Nguồn'
                    name='source'
                    rules={[{ required: true, message: 'Vui lòng chọn nguồn' }]}
                >
                    <Select
                        placeholder='Chọn nguồn'
                        options={partnerPackageDataCodeSourceOptions}
                    />
                </Form.Item>
                <Form.Item
                    label='Mô tả'
                    name='description'
                    rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Space>
                        <Button htmlType="submit" type="primary">Thêm</Button>
                        <Button htmlType="reset" type="dashed" danger>Clear</Button>
                    </Space>
                </div>
            </Form>
            <div style={{ marginTop: 20, overflow: 'auto' }}>
                <div style={{ maxHeight: 360, overflow: 'auto' }}>
                    <Table
                        rowKey={(_, index: any) => index}
                        style={{ overflow: 'auto' }}
                        columns={codeColumns}
                        dataSource={listAddCodes}
                        pagination={false}
                        footer={() => (
                            <div>Tổng {listAddCodes?.length || '0'} gói cước</div>
                        )}
                    />
                </div>
                <div style={{ textAlign: 'end', marginTop: 20 }}>
                    <Button
                        disabled={listAddCodes.length === 0}
                        onClick={() => setIsOpenModal(true)}
                        type="primary">Xác nhận</Button>
                </div>
            </div>
            <Modal title='Thêm gói cước' open={isOpenModal}
                onCancel={() => setIsOpenModal(false)}
                footer={[
                    <Button onClick={handleAddPackageDataCodes} type='primary'>
                        Thêm
                    </Button>,
                ]}>
                <p><span style={{ color: 'red' }}>*</span> Mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            </Modal>
        </div>
    )
}
