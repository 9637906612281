type APIErrorData = {
  message: string;
  statusCode: number;
};

export class APIError {
  _data: APIErrorData;

  constructor(message: string, statusCode: number) {
    // @ts-ignore
    this._data = { message, statusCode };
  }

  get data(): APIErrorData {
    return this._data;
  }

  set data(data: APIErrorData) {
    this._data = data;
  }

  get message(): string {
    return this._data.message;
  }
}

export const GRPCErrorCode = {
  DEADLINE_EXCEEDED: 4,
  NOT_FOUND: 5,
  SERVICE_UNAVAILABLE: 14,
  UNAUTHENTICATED: 16,
};
