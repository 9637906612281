import { Button, Divider, Input, Modal } from "antd";
import { useState } from "react";
import { toastError, toastSuccess } from "../../utils/toast";
import { apiUpdateStatusMaintain } from "../../api";
import { useLoading } from "../../context/LoadingContext";

export default function ModalMaintain({ isOpen, setIsOpen, data, fetchData }: any) {
    const [otpConfirm, setOtpConfirm] = useState('')

    const { setIsLoading } = useLoading()

    const handleUpdate = async () => {
        if (!otpConfirm) {
            toastError('Vui lòng nhập mã OTP xác nhận!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiUpdateStatusMaintain({
                value: data === 'true' ? 'false' : 'true',
                otpConfirm: otpConfirm,
            })
            if (resp.status === 200) {
                fetchData()
                setIsOpen(false)
                toastSuccess('Cập nhật trạng thái thành công')
                setOtpConfirm('')
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal
            title="Cập nhật trạng thái hệ thống"
            open={isOpen}
            onOk={() => alert()}
            onCancel={() => setIsOpen(false)}
            footer={[
                <Button onClick={handleUpdate} type="primary" danger={data === 'false'}>
                    {data === 'true' ? 'Mở lại' : 'Bảo trì'}
                </Button>
            ]}>
            <Divider />
            <p><span style={{ color: 'red' }}>*</span> Nhập mã OTP xác nhận</p>
            <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
            <Divider />
        </Modal>
    )
}
